import React from 'react';
import { Navbar } from 'react-bulma-components';
import { useHistory } from 'react-router';
import { useFetch } from '../../../api/ApiProvider';
import { idCodeAndTokenToAuthToken, useToken } from '../../../auth/TokenProvider';
import { usePromise } from '../../../shared/hooks';
import { isDev } from '../../../shared/utils';
import Select from '../../Select';

const DemoLogin = () => {
  const [, setToken] = useToken();
  const history = useHistory();
  const fetch = useFetch();
  const testUsers = usePromise(async () => isDev && fetch('/user', { errorNotification: false }), [], []);
  const onTestUserChange = user => {
    setToken(idCodeAndTokenToAuthToken(user.idCode, `${user.idCode}_token`));
    history.go(0);
  };
  return (
    <Navbar.Item className="header__personal__person">
      <Select
        menuPortalTarget={document.body}
        options={testUsers}
        placeholder="Demo login"
        onChange={onTestUserChange}
        getOptionValue={o => o.id}
        getOptionLabel={o => o.idCode}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 2 })
        }}
      />
    </Navbar.Item>
  );
};

export default DemoLogin;
