import { useCallback } from 'react';
import { useFetch } from '../api/ApiProvider';
import { useActiveLanguageCode } from '../i18n/LanguageProvider';

export const useGetAllRoles = () => {
  const fetch = useFetch();
  const activeLanguageCode = useActiveLanguageCode();

  return useCallback(
    (language = activeLanguageCode) =>
      fetch('/v2/dashboard', {
        queryParams: { language },
      }),
    [fetch, activeLanguageCode]
  );
};

export const useGetDashboard = () => {
  const fetch = useFetch();
  const activeLanguageCode = useActiveLanguageCode();

  return useCallback(
    (id, language = activeLanguageCode) =>
      fetch(`/v2/dashboard/${id}`, {
        queryParams: { language },
      }),
    [fetch, activeLanguageCode]
  );
};

export const useGetRegions = () => {
  const fetch = useFetch();
  const language = useActiveLanguageCode();

  return useCallback(
    parentId =>
      fetch('/element', {
        queryParams: { clfCode: 'EHAK', parentId, parentStrategy: 'INCLUDE', language },
      }),
    [fetch, language]
  );
};
