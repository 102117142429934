import React from 'react';
import Icon from '@mdi/react';
import { mdiPin, mdiArrowAll } from '@mdi/js';
import terms from '../assets/documents/terms_et.pdf';
import cookies from '../assets/documents/cookies_et.pdf';
const translations = {
  'stat.myDashboardPath': '/et/minu-juhtimislaud',
  'stat.url.dashboard': 'juhtimislaud',
  'stat.graph': 'graafik',
  'stat.lang.et': 'Eesti',
  'stat.lang.en': 'Inglise',
  'stat.app.title': 'Statistika juhtimislauad',
  'stat.header.options': 'Graafikute valik',
  'stat.header.dashboards': 'Juhtimislauad',
  'stat.header.dashboards.description': 'Visualiseeritud andmed kätte kiirelt ja mugavalt! Laadi alla! Jaga! Kasuta!',
  'stat.header.dashboards.description.loginNotification': 'Logi sisse ja loo oma juhtimislaud.',
  'stat.header.language': 'Keel',
  'stat.header.navItems': () => [
    { name: 'Koduleht', href: 'https://www.stat.ee/et/' },
    { name: 'Statistika andmebaas', href: 'https://andmed.stat.ee/et/stat' },
    { name: 'Palgarakendus', href: 'https://palgad.stat.ee/' },
    { name: 'Väliskaubanduse rakendus', href: 'https://valiskaubandus.stat.ee/profile/country/ee/?locale=et' },
    { name: 'Tõetamm', href: 'https://tamm.stat.ee/?lang=et' },
    { name: 'Nimede statistika rakendus', href: 'https://www.stat.ee/nimed' },
  ],
  'stat.header.feedback': 'Tagasiside',
  'stat.header.myDashboard': 'Minu juhtimislaud',
  'stat.header.logout': 'Logi välja',
  'stat.footer.statInfo': 'Statistikainfo',
  'stat.home.dashboard': 'Juhtimislauad',
  'stat.home.chooseDash': 'Vali juhtimislaud',
  'stat.home.chooseRegion': 'Vali piirkond',
  'stat.home.confirm': 'Kinnita valik',
  'stat.home.area': 'Piirkond',
  'stat.home.viewAll': 'Vaata kõiki',
  'stat.home.close': 'Sulge',
  'stat.share.download': 'Laadi alla',
  'stat.share': 'Jaga',
  'stat.share.link': 'Jaga lingiga',
  'stat.share.link.tooltip': 'Kopeeri link, et jagada interaktiivset graafikut',
  'stat.share.embed': 'Jaga manusena',
  'stat.share.embed.tooltip': 'Kasuta koodi graafiku manustamiseks oma veebilehele',
  'stat.share.graph': 'Jaga oma graafikut',
  'stat.share.graph.tooltip': 'Kopeeri link, et jagada enda loodud graafikut',
  'stat.share.print': 'Prindi',
  'stat.share.iePrint': 'Graafiku printimiseks palun lae graafik pildina alla',
  'stat.share.pin': 'Lisa minu juhtimislauale',
  'stat.share.unpin': 'Eemalda minu juhtimislaualt',
  'stat.share.pinGuest': 'Näitaja lisamiseks minu juhtimislauale logi sisse',
  'stat.chart.source': 'Allikas',
  'stat.chart.expectation': 'sihttase',
  'stat.chart.closeDetail': 'Sulge detailvaade',
  'stat.chart.closeThumb': 'Eemalda valik',
  'stat.chart.moveThumb': 'Liiguta valikut',
  'stat.chart.line': 'Joondiagramm',
  'stat.chart.bar': 'Tulpdiagramm',
  'stat.chart.pie': 'Sektordiagramm',
  'stat.chart.vertical': 'Rõhttulpdiagramm',
  'stat.chart.area': 'Pinddiagramm',
  'stat.chart.radar': 'Võrkdiagramm',
  'stat.chart.treemap': 'Puukaart',
  'stat.chart.map': 'Kaart',
  'stat.chart.pyramid': 'Rahvastikupüramiid',
  'stat.chart.stacked': 'Summatulpdiagramm',
  'stat.chart.info': 'Lisainfo',
  'stat.domains.activateAll': 'vali kõik',
  'stat.domains.activateAllUC': 'Vali kõik',
  'stat.domains.deactivateAll': 'eemalda kõik',
  'stat.domains.deactivateAllUC': 'Eemalda kõik',
  'stat.domains.confirm': 'Kinnita graafikute valik',
  'stat.domains.chooseFromDash': 'Vali graafikud valdkonna järgi',
  'stat.domains.chooseFromAll': ({ activateAll, deactivateAll }) => (
    <>
      Vali kõigi graafikute hulgast, {activateAll} või {deactivateAll}
    </>
  ),
  'stat.domains.allDashboards': 'Kõik juhtimislauad',
  'stat.widgets.options': 'Ava graafikute valik',
  'stat.widgetProvider.error': 'Graafiku laadimine ebaõnnestus.',
  'stat.widgetProvider.error.hiddenOrDeleted.short': 'Graafikut pole võimalik kuvada.',
  'stat.widgetProvider.error.hiddenOrDeleted': () => (
    <span>
      Graafikut pole võimalik kuvada. Otsi infot{' '}
      <a href="https://juhtimislauad.stat.ee/" rel="noopener noreferrer" target="_blank">
        juhtimislauad.stat.ee
      </a>
    </span>
  ),
  'stat.widgetProvider.noData': () => (
    <>
      <p>Andmed puuduvad.</p>
      <p>
        <small>Proovi teiste filtri valikutega uuesti.</small>
      </p>
    </>
  ),
  'stat.widgetInfo.moreInfo': 'Lisainfo',
  'stat.widgetInfo.explanation': 'Selgitus',
  'stat.widgetInfo.notes': 'Märkused',
  'stat.widgetInfo.methods': 'Mõisted ja metoodika',
  'stat.widgetInfo.baseData': 'Alusandmed statistika andmebaasis',
  'stat.widgetInfo.lastUpdate': 'Viimati uuendatud',
  'stat.widgetInfo.statJob': 'Statistikatöö',
  'stat.login.enter': 'Logi sisse',
  'stat.login.enterGuest': 'Jätka külalisena',
  'stat.login.title': 'Statistika juhtimislauad',
  'stat.login.intro': () => (
    <>
      <p>
        Milline on Eesti keskkonna, rahvastiku, sotsiaalne ja majandusolukord? Mis ja kuidas muutub? Vastuse annab
        statistika.
      </p>
      <p>
        Juhtimislaud on mugav töövahend, kus oluline riiklik statistika on esitatud visualiseerituna ja mugavalt
        kättesaadav vaid paari klõpsuga.
      </p>
      <p>Teadlikud otsused teed statistika juhtimislaua abil!</p>
      <p>
        Sisenedes nõustud{' '}
        <a href={terms} rel="noopener noreferrer" target="_blank">
          tingimustega.
        </a>
      </p>
    </>
  ),
  'stat.guestModal.title': 'Kas oled kindel?',
  'stat.guestModal.modalText':
    'Külalisena jätkates saad vaadata juhtimislaudu, kuid sinu valikuid ei salvestata ja sa ei saa luua oma juhtimislauda.',
  'stat.logout.confirm': 'Kas oled kindel, et soovid välja logida?',
  'stat.logout.confirmGuest': 'Külalisena tehtud valikud kustuvad, kui logid välja. Kas oled kindel?',
  'stat.guest': 'Külaline',
  'stat.firstVisit.hello': 'Tere',
  'stat.firstVisit.longText':
    'Esmakordselt juhtimislaual palume sul seadistada graafikute valik oma juhtimilauale. Valikud salvestatakse järgnevateks külastusteks, neid saad taas avada ja muuta',
  'stat.firstVisit.choices': 'Graafikute valiku',
  'stat.firstVisit.menu': 'menüüpunkti alt.',
  'stat.firstVisit.cookies': () => {
    return (
      <p>
        Sellel veebisaidil kasutatakse küpsiseid. Veebisaidi kasutamist jätkates nõustud küpsiste kasutamisega.
        Lisateavet küpsiste kasutamise kohta saad{' '}
        <a href={cookies} rel="noopener noreferrer" target="_blank">
          siit.
        </a>{' '}
        Sisenedes nõustud{' '}
        <a href={terms} rel="noopener noreferrer" target="_blank">
          tingimustega.
        </a>
      </p>
    );
  },
  'stat.cookie.compliance.heading': 'Küpsiste kasutamine',
  'stat.cookie.compliance.message': () => (
    <p>
      Mugavuse ja personaalsuse tagamiseks kasutatakse sellel veebilehel küpsiseid.{' '}
      <a href="https://www.stat.ee/et/statistikaamet/andmekaitse" rel="noopener noreferrer" target="_blank">
        Rohkem teavet
      </a>
      .
    </p>
  ),
  'stat.cookie.compliance.accept': 'Nõustun',
  'stat.cookie.compliance.reject': 'Ei soovi',
  'stat.cookie.compliance.settings': 'Küpsiste sätted',
  'stat.name': 'Statistikaamet',
  'stat.footer.dashboard': ' juhtimislauad',
  'stat.autocomplete.noMessageText': 'Tulemust ei leitud',
  'stat.myDashboard.readMore': 'Juhend',
  'stat.myDashboard.collapseText': () => {
    return (
      <div>
        <p>Minu juhtimislauale on võimalik kokku tõsta kõik huvipakkuvad graafikud erinevatelt juhtimislaudadelt.</p>
        <ul style={{ listStyleType: 'disc' }}>
          <li>
            Oma juhtimislauale graafiku lisamiseks vali sobiv graafik olemasolevalt juhtimislaualt ja klõpsa graafiku
            üleval paremas nurgas asuvale nupule <Icon color="rgb(0,0,0)" path={mdiPin} size={1} />.
          </li>
          <li>
            Graafikute järjekorra muutmiseks klõpsa nupul <Icon color="rgb(0,0,0)" path={mdiArrowAll} size={1} /> ja
            lohista graafik sobivasse asukohta.
          </li>
          <li>
            Graafiku eemaldamiseks oma juhtimislaualt klõpsa paremal üleval nurgas asuvale nupule{' '}
            <Icon color="white" path={mdiPin} size={1.3} className="pin-icon-instructions" />.
          </li>
        </ul>
      </div>
    );
  },
  'stat.myDashboard.newFeatureText': () => {
    return (
      <div>
        <p>
          Sinu jaoks olulistest andmetest parema ülevaate saamiseks oleme loonud võimaluse erinevatelt juhtimislaudadelt
          pärit graafikutest luua personaalne juhtimislaud.
        </p>
        <p>
          Selle juhtimislaua leiad vajutades lehe paremas ääres oma kasutajanimele ning klõpsates sealt avanevas
          rippmenüüs nupule 'Minu juhtimislaud'.
        </p>
        <p>
          Täpsema juhendi kuidas valitud graafikuid oma juhtimislauale salvestada ning neid sealt eemaldada leiad 'Minu
          juhtimislaud' lehel paiknevast juhendist.
        </p>
        <p>Kas soovid jätkata liikudes oma juhtimislaua lehele või olemasolevate juhtimislaudade valikusse?</p>
      </div>
    );
  },
  'stat.myDashboard.newFeatureHeader': 'Loo oma juhtimislaud!',
  'stat.myDashboard.navigateTo': 'Minu juhtimislaud',
  'stat.filters.maximumOptionsMessage': ({ numberOfOptions }) =>
    `Valida saab ${numberOfOptions === 1 ? '' : 'kuni '}${numberOfOptions} ${
      numberOfOptions === 1 ? 'väärtuse' : 'väärtust'
    }`,
  'stat.embed.widgetUrl': ({ widgetUrl }) => (
    <>
      Vaata interaktiivset graafikut{' '}
      <a href={widgetUrl} target="_blank" rel="noopener noreferrer">
        juhtimislauad.stat.ee
      </a>
    </>
  ),
  'components.Select.MenuList.selectAll': 'Vali kõik nähtavad valikud',
  'components.Select.MenuList.placeholder': 'Vali..',
  'components.Select.MenuList.placeholderRegion': 'Vali piirkond',
  'components.Select.Placeholder.choose': 'Vali ',
  'components.Select.Async.loadingMessage': 'Laadin valikuid',
  'components.Chart.week': ({ weekNr, year }) => `${weekNr}. nädal ${year}`,
  'components.Chart.weekLong': ({ weekNr, startDate, endDate }) => `${weekNr}. nädal (${startDate}–${endDate})`,
  'api.operationPending': 'Töötlen päringut, palun oota..',
  'api.operationFailed': 'Päring ebaõnnestus',
  'api.pinSuccess': 'Lisamine õnnestus',
  'api.unpinSuccess': 'Eemaldamine õnnestus',
  'api.pinFailed': 'Lisamine ebaõnnestus',
  'api.unpinFailed': 'Eemaldamine ebaõnnestus',
  'api.myDashboard.notExist': 'Minu juhtimislaua loomiseks lisage sinna graafikuid',
  'auth.sessionExpired': 'Sinu sessioon on aegunud, oled välja logitud.',
  'auth.loggedOut': 'Oled välja logitud.',
  'error.dashboard_is_not_existing': 'Juhtimislauda ei leitud',
  'error.dashboard_is_hidden': 'Juhtimislaud on peidetud',
  'error.dashboard_must_be_regional': 'Juhtimislaud peab olema regionaalne',
  'error.my_dashboard_does_not_exist': 'Juhtimislauda ei leitud',
  'error.my_dashboard_must_have_user': 'Juhtimislaual peab olema kasutaja',
  'error.widget_is_not_existing': 'Kuvamoodulit ei leitud',
  'error.widget_is_not_existing_or_added_to_dashboard': 'Kuvamoodulit ei leitud või see on juba lisatud juhtimislauale',
  'error.widget_is_hidden': 'Kuvamoodul on peidetud',
  'error.user_widget_is_not_existing': 'Kuvamoodulit ei leitud',
  'error.uploaded_file_has_no_name': 'Üleslaetav fail on nimetu',
  'error.uploaded_file_has_no_extension': 'Üleslaetaval failil puudub faililaiend',
  'error.uploaded_file_is_not_excel': 'Üleslaetav fail ei ole Exceli formaadis',
  'error.excel_filters_do_not_match': 'Exceli faili filtrid ei klapi',
  'error.excel_filter_values_do_not_match': 'Exceli faili filtri väärtused ei klapi',
  'error.user_dashboards_are_not_visible_to_admin': 'Kasutaja juhtimislauad ei ole administraatorile nähtavad',
  'error.dashboard_admin_search_too_short': 'Otsingusõna on liiga lühike',
  'error.dashboard_admin_ehak_role_must_have_no_subrole': 'Ehak rollil ei saa olla alamrolle',
  'error.dashboard_admin_role_must_have_subrole': 'Rollil peavad olema alamrollid',
  'error.dashboard_admin_regional_dashboard_must_have_region': 'Juhtimislaual peab olema määratud regioon',
  'error.dashboard_admin_regional_dashboard_invalid_region': 'Juhtimislauale määratud regioon ei ole valiidne',
  'error.dashboard_admin_dashboard_must_have_status': 'Juhtimislaual peab olema staatus',
  'error.dashboard_admin_name_is_mandatory': 'Nimi on kohustuslik',
  'error.dashboard_admin_name_already_exists': 'Nimi on juba kasutusel',
  'error.dashboard_widget_unknown_ehak': 'Kuvamoodulil on tundmatu ehak kood',
  'error.widget_admin_query_too_short': 'Otsingusõna on liiga lühike',
  'error.widget_admin_end_date_incorrect': 'Kuvamooduli lõppkuupäev ei ole korrektne',
  'error.widget_admin_start_date_incorrect': 'Kuvamooduli alguskuupäev ei ole korrektne',
  'error.widget_admin_start_is_after_end': 'Kuvamooduli alguskuupäev on suurem kui lõppkuupäev',
  'error.widget_admin_cube_code_missing': 'Kuvamooduli kuubikood on puudu',
  'error.widget_admin_excel_name_missing': 'Exceli failinimi on puudu',
  'error.widget_admin_dimensions_missing': 'Kuvamooduli dimensioonid on puudu',
  'error.widget_admin_periods_must_be_positive': 'Kuvamooduli perioodid peavad olema positiivsed',
  'error.widget_admin_time_period_missing': 'Kuvamooduli ajaperiood on puudu',
  'error.widget_admin_status_missing': 'Kuvamooduli staatus on puudu',
  'error.widget_admin_name_en_missing': 'Kuvamooduli ingliskeelne nimi on puudu',
  'error.widget_admin_name_et_missing': 'Kuvamooduli eestikeelne nimi on puudu',
  'error.widget_admin_shortname_et_missing': 'Kuvamooduli eestikeelne lühinimi on puudu',
  'error.widget_admin_shortname_en_missing': 'Kuvamooduli ingliskeelne lühinimi on puudu',
  'error.domain_admin_shortname_et_missing': 'Domeeni eestikeelne lühinimi on puudu',
  'error.domain_admin_shortname_en_missing': 'Domeeni ingliskeelne lühinimi on puudu',
  'error.domain_admin_levelname_et_missing': 'Domeeni tasemenimi on puudu',
  'error.domain_admin_levelname_et_invalid': 'Domeeni tasemenimi ei ole korrektne',
  'error.graph_type_admin_no_type': 'Graafikul puudub tüüp',
  'error.graph_type_admin_excel_only_line_and_bar': 'Graafik saab olla vaid joon- või tulpdiagramm',
  'error.graph_type_admin_line_bar_stacked_area_must_have_time_axis': 'Graafikul peab olema ajatelg',
  'error.filter_admin_no_type': 'Filtril puudub tüüp',
  'error.filter_admin_no_name_et': 'Filtril puudub eestikeelne nimi',
  'error.filter_admin_no_name_en': 'Filtril puudub ingliskeelne nimi',
  'error.filter_admin_time_part_filter_must_have_time_part': 'Filtril peab olema ajaline mõõde',
  'error.filter_admin_time_axis_must_have_0_values': 'Filtri ajateljel peab olema 0 väärtust',
  'error.filter_admin_filter_must_have_values': 'Filtrile peab olema määratud väärtus',
  'error.filter_admin_axis_only_1': 'Filtril saab olla vaid 1 telg',
  'error.filter_admin_axis_must_have_order': 'Filtri telg peab olema järjestatud',
  'error.filter_admin_max_1_legend': 'Filtril saab olla vaid 1 legend',
  'error.filter_admin_max_4_legend_menu': 'Filtril saab olla maksimaalselt 4 legendimenüüd',
  'error.filter_admin_max_1_region': 'Filtril saab olla vaid 1 regioon',
  'error.filter_admin_max_1_time': 'Filtril saab olla vaid üks ajaline väärtus',
  'error.filter_value_admin_no_value_en': 'Filtril puudub ingliskeelne väärtus',
  'error.filter_value_admin_no_value_et': 'Filtril puudub eestikeelne väärtus',
  'error.widget_admin_widget_exists_with_another_connection': 'Kuvamoodulil on juba olemasolev seos',
  'error.illegal_graph_type': 'Graafiku tüüp ei ole korrektne',
  'error.missing_graph_type': 'Graafiku tüüp on puudu',
  'error.missing_region': 'Regioon on puudu',
  'error.dashboard_exists_with_another_connection': 'Juhtimislaual on juba olemasolev seos',
};
export default translations;
