import React from 'react';
import { Navbar } from 'react-bulma-components';
import { useDrawer, useMenu } from '../../../dashboard/UIProvider';

const NavMobileBurger = () => {
  const { closeDrawer } = useDrawer();
  const { toggleMenu } = useMenu();
  const clickHandler = () => {
    toggleMenu();
    closeDrawer();
  };

  return (
    <div className="navbar-item-mobile">
      <Navbar.Burger role="button" aria-label="menu" aria-expanded="false" onClick={clickHandler} />
    </div>
  );
};

export default NavMobileBurger;
