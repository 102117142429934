import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

const Context = createContext({ widgets: [] });

export const WidgetCacheProvider = props => {
  const widgets = useState({});
  const pendingWidgets = useRef({});

  const value = {
    widgets,
    pendingWidgets
  };

  return <Context.Provider {...props} value={value} />;
};

export const useCachedWidget = widgetId => {
  const [widgets, setWidgets] = useContext(Context).widgets;
  const localCache = useState();

  const cachedWidget = widgets && widgets[widgetId];
  const setCachedWidget = useCallback(
    widget =>
      setWidgets(prevState => ({
        ...prevState,
        [widgetId]: widget
      })),
    []
  );

  return widgets && setWidgets ? [cachedWidget, setCachedWidget] : localCache;
};

export const usePendingWidgetState = widgetId => {
  const { pendingWidgets } = useContext(Context);

  const isPending = useCallback(() => pendingWidgets && pendingWidgets.current[widgetId], [widgetId]);
  const setPending = useCallback(
    value => {
      if (pendingWidgets) pendingWidgets.current[widgetId] = value;
    },
    [widgetId]
  );

  return [isPending, setPending];
};

export default WidgetCacheProvider;
