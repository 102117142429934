import React from 'react';
import { IntlProvider } from 'react-intl';
import NewContextProvider from './IntlProvider';
import { LanguageContext } from './LanguageProvider';

const ConnectedIntlProvider = ({ children }) => (
  <LanguageContext.Consumer>
    {({ activeLanguageCode }) => (
      <IntlProvider locale={activeLanguageCode} textComponent={React.Fragment}>
        <NewContextProvider>{children}</NewContextProvider>
      </IntlProvider>
    )}
  </LanguageContext.Consumer>
);

export default ConnectedIntlProvider;
