import React, { useEffect } from 'react';
import { Button, Card } from 'react-bulma-components';
import { useCookies } from 'react-cookie';
import { Translate } from '../../i18n/LanguageProvider';
import './Notification.scss';
import './CookieNotification.scss';
import {
  addCookieScript,
  CookieConsentValue,
  COOKIE_CONSENT_AGREED_NAME,
  COOKIE_CONSENT_EXPIRATION_MS,
  COOKIE_NAME_LIST,
  removeCookieScript,
  cookieOptions,
} from '../../shared/cookieUtils';

const CookieNotification = ({ show, onShow }) => {
  const [cookies, setCookie, removeCookie] = useCookies([...COOKIE_NAME_LIST, COOKIE_CONSENT_AGREED_NAME]);

  const consentAgreedCookieValue = cookies[COOKIE_CONSENT_AGREED_NAME];
  const consentCookieIsSet = consentAgreedCookieValue !== undefined;

  const showNotification = !consentCookieIsSet || show === true;

  const cookieNotificationClassName = ['eu-cookie-compliance-notification', !showNotification ? 'hidden' : '']
    .join(' ')
    .trim();

  const removeAllCookies = () => {
    COOKIE_NAME_LIST.forEach(cookieName => {
      removeCookie(cookieName, cookieOptions);
    });
  };

  useEffect(() => {
    if (consentAgreedCookieValue === CookieConsentValue.accept) {
      addCookieScript();
    }
  }, [consentAgreedCookieValue]);

  useEffect(() => {
    if (!consentCookieIsSet || consentAgreedCookieValue === CookieConsentValue.reject) {
      removeAllCookies();
    }
  }, [consentCookieIsSet, consentAgreedCookieValue]);

  const handleAcceptCookies = () => {
    onShow(false);
    setCookie(COOKIE_CONSENT_AGREED_NAME, CookieConsentValue.accept, {
      path: '/',
      sameSite: 'strict',
      expires: new Date(COOKIE_CONSENT_EXPIRATION_MS),
    });
    if (consentCookieIsSet) {
      window.location.reload();
    }
  };

  const handleRejectCookies = () => {
    onShow(false);
    setCookie(COOKIE_CONSENT_AGREED_NAME, CookieConsentValue.reject, {
      path: '/',
      sameSite: 'strict',
      expires: new Date(COOKIE_CONSENT_EXPIRATION_MS),
    });
    removeAllCookies();
    removeCookieScript();
    if (consentCookieIsSet) {
      window.location.reload();
    }
  };

  return (
    <div
      className={cookieNotificationClassName}
      role="alertdialog"
      aria-labelledby="popup-text"
      aria-hidden={Boolean(!showNotification)}
    >
      <Card>
        <Card.Content id="popup-text">
          <h2 className="heading">
            <Translate>stat.cookie.compliance.heading</Translate>
          </h2>
          <div className="message">
            <Translate>stat.cookie.compliance.message</Translate>
          </div>
          <div className="actions">
            <Button className="is-secondary" onClick={handleRejectCookies}>
              <Translate>stat.cookie.compliance.reject</Translate>
            </Button>
            <Button className="is-primary" onClick={handleAcceptCookies}>
              <Translate>stat.cookie.compliance.accept</Translate>
            </Button>
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};

export default CookieNotification;
