import { useContext } from 'react';
import { useWidget } from '../dashboard/WidgetProvider';
import { tryToMap } from '../export/utils';
import { IntlContext } from './IntlProvider';
import { useActiveLanguageCode, useTranslate } from './LanguageProvider';
import { getISOWeek, getISOWeekYear, addDays, format } from 'date-fns';
import { et, enGB } from 'date-fns/locale';

export const FormattedDate = ({ value, ...props }) => useFormatDate()(value, props);
export const FormattedNumber = ({ value, ...props }) => useFormatNumber()(value, props);

export const useFormatDate = () => {
  const widget = useWidget();
  const activeLanguageCode = useActiveLanguageCode();
  const intl = useContext(IntlContext);
  const translate = useTranslate();

  const formatDate = (
    date,
    { short, periodType = short ? appendShort(widget.timePeriod) : widget.timePeriod, locale = intl.locale } = {}
  ) => {
    const parsedDate = new Date(date);

    const formatFunction = tryToMap(date =>
      locale === activeLanguageCode
        ? intl.formatDate(date, getIntlConf(periodType))
        : new Intl.DateTimeFormat(locale, getIntlConf(periodType)).format(date)
    );

    const month = parsedDate.getMonth();
    const quarter = Math.ceil((month + 1) / 3);
    const quarterString = (locale === 'en'
      ? ['1st', '2nd', '3rd', '4th'].map(q => `${q} quarter`)
      : ['I', 'II', 'III', 'IV'].map(q => `${q} kvartal`))[quarter - 1];

    const shortQuarterString = ['Q1', 'Q2', 'Q3', 'Q4'][quarter - 1];

    switch (periodType) {
      case 'WEEK':
        return translate('components.Chart.week', { weekNr: getISOWeek(parsedDate), year: getISOWeekYear(parsedDate) });
      case 'WEEK_LONG':
        return translate('components.Chart.weekLong', {
          weekNr: getISOWeek(parsedDate),
          startDate: formatFunction(parsedDate),
          endDate: formatFunction(addDays(parsedDate, 6))
        });
      case 'QUARTER':
        return `${quarterString} ${formatDate(parsedDate, { periodType: 'YEAR' })}`;
      case 'QUARTER_ONLY':
        return quarterString;
      case appendShort('QUARTER'):
        return `${shortQuarterString} ${formatDate(parsedDate, { periodType: 'YEAR' })}`;
      case appendShort('QUARTER_ONLY'):
        return shortQuarterString;
      default:
        return formatFunction(parsedDate);
    }
  };

  return formatDate;
};

const getIntlConf = period => {
  switch (period) {
    case 'WEEK_LONG':
      return { day: '2-digit', month: '2-digit', year: 'numeric' };
    case 'MONTH':
      return { month: 'long', year: 'numeric' };
    case appendShort('MONTH'):
      return { month: 'short', year: 'numeric' };
    case 'MONTH_ONLY':
      return { month: 'long' };
    case appendShort('MONTH_ONLY'):
      return { month: 'short' };
    default:
      return { year: 'numeric' };
  }
};

const appendShort = value => `${value}_SHORT`;

export const useFormatNumber = () => {
  const widget = useWidget();
  const intl = useContext(IntlContext);
  const activeLanguageCode = useActiveLanguageCode();

  return (
    number,
    {
      style = 'decimal',
      minimumFractionDigits = widget.precisionScale,
      maximumFractionDigits = minimumFractionDigits,
      locale = activeLanguageCode
    } = {}
  ) => {
    const intlConf = {
      style,
      minimumFractionDigits: minimumFractionDigits === false ? undefined : minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits === false ? undefined : maximumFractionDigits
    };

    return locale === activeLanguageCode
      ? intl.formatNumber(number, intlConf)
      : new Intl.NumberFormat(locale, intlConf).format(number);
  };
};

export const useLocalizedDate = date => {
  const activeLanguageCode = useActiveLanguageCode();
  return {
    et: format(new Date(date), et.formatLong.date({ width: 'long' }), {
      locale: et
    }),
    en: format(new Date(date), enGB.formatLong.date({ width: 'long' }), {
      locale: enGB
    })
  }[activeLanguageCode];
};
