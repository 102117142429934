export const colorToRGBA = color => {
  const canvas = document.createElement('canvas');
  canvas.width = canvas.height = 1;
  const ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, 1, 1);
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);
  return [...ctx.getImageData(0, 0, 1, 1).data];
};

export const COLOR_THEME = {
  primary: 'rgba(63,26,132, 1)',
  secondary: 'rgba(245,143,169, 1)',
  tertiary: 'rgba(255,214,69, 1)',
  primaryOpacity: 'rgba(63,26,132, .5)',
  secondaryOpacity: 'rgba(245,143,169, .5)',
  tertiaryOpacity: 'rgba(255,214,69, .5)',
  grey: 'hsl(0, 0%, 48%)',
  greyLight: 'hsl(0, 0%, 71%)',
  greyLighter: 'hsl(0, 0%, 86%)',
  whiteTer: 'hsl(0, 0%, 96%)'
};

export const COLOR_SCALE = [
  'rgb(63,26,132)', // dark violet
  'rgb(245,143,169)', // pink
  'rgb(255,214,69)', // yellow
  'rgb(58,216,204)', // marine
  'rgb(15,79,239)', // blue
  'rgb(247,102,79)', // tomato
  'rgb(0,0,0)', // black
  'rgb(240,167,100)', // apricot
  'rgb(212,17,155)', // rasbperry
  'rgb(199,184,245)', // light violet
  'rgb(103,109,39)', // moos
  'rgb(253,192,183)', // baby-pink
  'rgb(3,122,117)', // calm green
  'rgb(180,177,181)', // gray
  'rgb(238,212,181)', // sand
  'rgb(67,199,246)', // lightblue
  'rgb(173,36,15)', // darkred
  'rgb(216,163,29)', // safari
  'rgb(22,38,89)', // nightblue
  'rgb(239,235,106)', // lemon
  'rgb(158,183,213)', // calm gray
  'rgb(124,65,189)', // violet
  'rgb(153,26,84)', // another red
  'rgb(77,193,77)' //lightgreen
];

export const COLOR_SCALE_EXPECTED = COLOR_SCALE.map(colorToRGBA).map(([r, g, b]) => `rgba(${r}, ${g}, ${b}, 0.5)`);

export const DEFAULT_MARGIN = {
  top: 30,
  right: 20,
  left: 0,
  bottom: 0
};

export const reactSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: 'hsla(0, 0%, 0%, .5)',
    primary: 'hsla(0, 0%, 0%, 1)'
  }
});
