import React, { useCallback, useContext } from 'react';
import { useHistory, withRouter } from 'react-router';
import { useFetch } from '../api/ApiProvider';
import { useTranslate } from '../i18n/LanguageProvider';
import { toastError, toastInfo, toastSuccess } from '../notifications';
import { clearDashboardPreferences } from '../preferences/dashboard';
import { clearWidgetPreferences } from '../preferences/widget';
import { usePromiseWithRefresh } from '../shared/hooks';
import { useToken } from './TokenProvider';
import { isAppEmbedded, isDev } from '../shared/utils';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const getCurrentUser = useGetCurrentUser();
  const [user, setUser] = usePromiseWithRefresh(getCurrentUser, [getCurrentUser]);
  const fetch = useFetch();

  const setSeenNews = () =>
    fetch('/me/news', {
      method: 'PATCH',
      body: { seenTheNews: true },
    }).then(setUser);

  const value = { user: user, showNews: user ? user.showNews : false, setSeenNews: setSeenNews };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useGetCurrentUser = () => {
  const fetch = useFetch();
  const [token, setToken] = useToken();
  const translate = useTranslate();
  const isGuest = useIsGuest();
  const history = useHistory();

  return useCallback(
    async () =>
      isGuest
        ? {
            roles: ['ROLE_GUEST'],
          }
        : fetch('/me', { errorNotification: false }).catch(response => {
            if (response.status === 401 && token) {
              toastInfo(translate('auth.sessionExpired'));
              setToken('guest');
              history.push('/');
            } else if (token) {
              toastError(translate('api.operationFailed'));
            }
          }),
    [token, setToken, fetch, translate]
  );
};

export const useLogout = () => {
  const fetch = useFetch();
  const translate = useTranslate();
  const [, setToken] = useToken();
  const isGuest = useIsGuest();
  const history = useHistory();

  return useCallback(async () => {
    if (!window.confirm(translate(isGuest ? 'stat.logout.confirmGuest' : 'stat.logout.confirm'))) return;

    try {
      if (!isDev) {
        await fetch('/session/logout', { method: 'POST', errorNotification: false });
      }
    } catch (e) {}

    clearDashboardPreferences();
    clearWidgetPreferences();
    toastSuccess(translate('auth.loggedOut'));
    setToken('guest');
    history.push('/');
  }, [isGuest, fetch, setToken, translate]);
};

export const useCurrentUser = () => useContext(UserContext).user;

export const useIsGuest = () => {
  const [token] = useToken();

  return token === 'guest' || isAppEmbedded;
};

export const useRoles = () => {
  const { roles = [] } = useCurrentUser() || {};
  return roles;
};

export const useSeenNews = () => {
  const { showNews, setSeenNews } = useContext(UserContext);
  return [showNews, setSeenNews];
};

export const useIsAdmin = () => useRoles().includes('ROLE_ADMIN');

export const useIsLoggedIn = () => {
  const [token] = useToken();
  const user = useCurrentUser();

  return !!(token && user);
};

export default withRouter(UserProvider);
