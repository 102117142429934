import { flatten } from 'lodash';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFetch } from '../api/ApiProvider';
import { useFormatDate } from '../i18n/hooks';
import { Translate, useActiveLanguageCode, useTranslate } from '../i18n/LanguageProvider';
import { useRegion } from '../preferences/dashboard';
import { useFilterValue, useWidgetQuery } from '../preferences/widget';
import { useIsMounted, useSlugIds } from '../shared/hooks';
import { wrapToArray } from '../shared/utils';
import { useCachedWidget, usePendingWidgetState } from '../widget/WidgetCacheProvider';
import { DashboardContext, useIsPersonalDashboard } from './DashboardProvider';
import { toastError } from '../notifications';

export const WidgetContext = React.createContext({});

const WidgetProvider = ({ widgetId, children, getWidgetUrl, sharingDisabled = false }) => {
  const [widget, refreshWidget, widgetError] = useRefreshingWidget(widgetId, getWidgetUrl);
  const { graphId } = useSlugIds();
  const translate = useTranslate();
  const shortWidgetError = translate('stat.widgetProvider.error.hiddenOrDeleted.short');

  const value = useMemo(
    () => ({ widgetId, widget, refreshWidget, sharingDisabled }),
    [{ widgetId, widget, refreshWidget, sharingDisabled }]
  );

  if (widgetError) return <WidgetError errorMessage={graphId ? shortWidgetError : widgetError} />;
  if (!widget) return null;

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

const WidgetError = ({ errorMessage }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '2rem',
      color: 'lightgray',
      height: '100%',
      minHeight: 300,
    }}
  >
    <Translate>{errorMessage}</Translate>
  </div>
);

export default WidgetProvider;

/* Hooks */

export const useRefreshingWidget = (widgetId, getWidgetUrl) => {
  const getWidget = useGetWidget(widgetId, getWidgetUrl);
  const [widget, setWidget] = useCachedWidget(widgetId);
  const [widgetError, setWidgetError] = useState(false);
  const [isPending, setPending] = usePendingWidgetState(widgetId);
  const isMounted = useIsMounted();

  const refreshWidget = useCallback(() => {
    if (!isPending()) {
      setPending(true);
      getWidget()
        .then(widget => {
          if (isMounted()) {
            setWidget(widget);
            setWidgetError(false);
            setPending(false);
          }
        })
        .catch(e => {
          if (isMounted()) {
            setWidgetError(e.message);
            setPending(false);
          }
        });
    }
  }, [getWidget]);

  useEffect(() => {
    refreshWidget();
  }, [refreshWidget]);

  return [widget, refreshWidget, widgetError];
};

export const useGetWidget = (
  widgetId,
  getUrl = ({ dashboardId, widgetId }) => `/dashboard/${dashboardId}/widget/${widgetId}`
) => {
  const fetch = useFetch();
  const [region] = useRegion();
  const { dashboardId } = useContext(DashboardContext);
  const activeLanguageCode = useActiveLanguageCode();
  const query = useWidgetQuery();
  const translate = useTranslate();

  return useCallback(
    (languageCode = activeLanguageCode) =>
      fetch(getUrl({ dashboardId, widgetId }), {
        queryParams: { ...query, language: languageCode, ehakId: region },
        errorNotification: false,
      }).catch(response => {
        try {
          return response.json().then(error => {
            if (error.httpStatus === 400) {
              throw new Error('stat.widgetProvider.error.hiddenOrDeleted');
            } else {
              toastError(translate('api.operationFailed'));
            }
            throw error;
          });
        } catch (e) {
          throw new Error('stat.widgetProvider.error');
        }
      }),
    [activeLanguageCode, dashboardId, widgetId, region, qs.stringify(query)]
  );
};

export const useWidgetId = () => useContext(WidgetContext).widgetId;
export const useWidget = () => useContext(WidgetContext).widget;
export const useIsSharingDisabled = () => useContext(WidgetContext).sharingDisabled;

export const useDescriptiveWidgetName = () => {
  const contextWidget = useWidget();
  const getFilterValues = useWidgetFilterValues();
  const activeLanguageCode = useActiveLanguageCode();
  const formatDate = useFormatDate();

  return (widget = contextWidget, languageCode = activeLanguageCode) => {
    const { name, timePeriod, diagram: { period } = {} } = widget;

    const divider = timePeriod === 'YEAR' ? '\u2013' : ' \u2013 ';
    const formatDateUsingLocale = value => formatDate(value, { locale: languageCode });

    return `${name} | ${[
      ...getFilterValues(widget, languageCode),
      period ? `${formatDateUsingLocale(period.from)}${divider}${formatDateUsingLocale(period.to)}` : null,
    ]
      .filter(x => x)
      .join(', ')}`;
  };
};

export const useWidgetFilterValues = () => {
  const contextWidget = useWidget();
  const formatDate = useFormatDate();
  const activeLanguageCode = useActiveLanguageCode();
  const [getFilterValue] = useFilterValue();

  const mapFilterToOptions = ({ id: filterId, values: options, time }) => {
    const value = getFilterValue(filterId);
    const hasValue = value !== undefined;

    const defaultOptions = options.filter(option => option.selected);
    const selectedOptions = wrapToArray(value).map(optionId => options.find(option => option.id === optionId));

    const finalOptions = (hasValue ? selectedOptions : defaultOptions)
      .filter(Boolean)
      .map(option => ({ ...option, time }));

    return time ? finalOptions.sort(compareOptionsByDate) : finalOptions;
  };

  return (widget = contextWidget, languageCode = activeLanguageCode) => {
    const formatOptionAsDate = ({ option, time }) => (time ? formatDate(option, { locale: languageCode }) : option);

    const { diagram = {} } = widget;
    const { filters = [] } = diagram;

    return flatten(filters.filter(isNotLegend).map(mapFilterToOptions)).map(formatOptionAsDate);
  };
};

const compareOptionsByDate = (a, b) => new Date(a.option) - new Date(b.option);

const isNotLegend = ({ type }) => type !== 'LEGEND';

export const useDomainNames = () => {
  const widget = useWidget();
  const isPersonalDashboard = useIsPersonalDashboard();
  const element = isPersonalDashboard
    ? widget.dashboards
      ? widget.dashboards[0]
      : null
    : widget.elements
    ? widget.elements[0]
    : null;
  return element
    ? element.parent
      ? `${element.parent.levelName} ${element.parent.name} > ${element.levelName}`
      : `${element.showLevelName ? `${element.levelName} ` : ``}${element.name}`
    : null;
};
