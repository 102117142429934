import React from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar } from 'react-bulma-components';
import NavItemsSecondary from './NavItemsLinks';
import LangSelector from './LangSelector';
import './NavSecondary.scss';
import { useMedia } from '../../../shared/hooks';
import { useCurrentUser, useLogout, useIsGuest } from '../../../auth/UserProvider';
import { useTranslate } from '../../../i18n/LanguageProvider';
import Select from '../../../components/Select/';
import { isDev } from '../../../shared/utils';
import Login from '../Login';
import DemoLogin from '../Login/DemoLogin';

const NavSecondary = () => {
  const user = useCurrentUser();
  const isGuest = useIsGuest();
  const logout = useLogout();
  const isMobile = useMedia(['(max-width: 768px)', '(min-width: 769px)'], [true, false], false);
  const isTablet = useMedia(['(max-width: 1023px)', '(max-width: 769px)'], [true, false], false);

  return (
    <div className="navbar-wrapper navbar-secondary">
      <Navbar color={'dark'} aria-label="links navigation" className="navbar-secondary">
        <Navbar.Menu>
          <Navbar.Container>
            <NavItemsSecondary />
          </Navbar.Container>
          {!isMobile && !isTablet && (
            <Navbar.Container position="end">
              {!isGuest && <NavItemUser onClickLogout={logout} user={user} />}
              {isGuest && isDev && <DemoLogin />}
              {isGuest && !isDev && <Login />}
              <Navbar.Item>
                <LangSelector />
              </Navbar.Item>
            </Navbar.Container>
          )}
        </Navbar.Menu>
      </Navbar>
    </div>
  );
};

export const NavItemUser = ({ user, onClickLogout }) => {
  const isGuest = useIsGuest();
  const history = useHistory();
  const translate = useTranslate();

  const userMenuOptions = [
    { value: 'user', name: user ? user.username : null },
    ...(!isGuest ? [{ value: 'myDashboard', name: translate('stat.header.myDashboard') }] : []),
    { value: 'logOut', name: translate('stat.header.logout') }
  ];

  const onMenuOptionChange = ({ value }) =>
    value === 'myDashboard' ? history.push(`${translate('stat.myDashboardPath')}`) : onClickLogout();

  return (
    <Navbar.Item className="header__personal__person">
      <Select
        menuPortalTarget={document.body}
        value={userMenuOptions[0]}
        options={userMenuOptions}
        onChange={onMenuOptionChange}
        getOptionValue={o => o.value}
        getOptionLabel={o => o.name}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 2 }),
          menu: base => ({ ...base, width: 'max-content', minWidth: '100%' })
        }}
        hideSelectedOptions
      />
    </Navbar.Item>
  );
};

export default NavSecondary;
