import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Columns, Container, Hero, Section, Card, Heading, Tabs, Button } from 'react-bulma-components';
import Header from '../../components/Header';
import Select from '../../components/Select/';
import Footer from '../../components/Footer/Footer';
import { mdiChevronRightCircle, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { useGetAllRoles, useGetRegions } from '../../dashboard/api';
import { Translate, useActiveLanguageCode } from '../../i18n/LanguageProvider';
import { usePromise, useMedia, useLocalStorage } from '../../shared/hooks';
import { capitalizeRegionName, createDangerousHTML, getSlug } from '../../shared/utils';
import './Home.scss';
import { useFetch } from '../../api/ApiProvider';
import { useIsGuest } from '../../auth/UserProvider';

const Home = () => {
  const isMobile = useMedia(['(max-width: 768px)', '(min-width: 769px)'], [true, false], false);
  const getAllRoles = useGetAllRoles();
  const isGuest = useIsGuest();

  const roles = usePromise(getAllRoles, [getAllRoles], []);

  return (
    <>
      <div className="home">
        <Header />
        <Hero color="white">
          <Hero.Body className="dotted">
            <Container>
              <Heading renderAs={isMobile ? 'h4' : 'h2'} size={isMobile ? 4 : 2}>
                <Translate>stat.header.dashboards</Translate>
                <Heading subtitle renderAs="div">
                  <Translate>stat.header.dashboards.description</Translate>
                  <br />
                  {isGuest && <Translate>stat.header.dashboards.description.loginNotification</Translate>}
                </Heading>
              </Heading>
            </Container>
          </Hero.Body>
        </Hero>
        <Section>
          <Container>
            {isMobile && <MobileLayout roles={roles}></MobileLayout>}
            {!isMobile && <DesktopLayout roles={roles}></DesktopLayout>}
          </Container>
        </Section>
      </div>
      <Footer />
    </>
  );
};

const DesktopLayout = ({ roles }) => {
  const [expandedMenus, setExpandedMenus] = useState([]);

  return roles.map((role, i) => (
    <Section key={i}>
      <Heading renderAs="h3" size={3}>
        {role.mainRole.name}
      </Heading>
      <Heading subtitle> {role.mainRole.description}</Heading>
      <Columns breakpoint="mobile" role="main">
        {role.subRoles.slice(0, 4).map(subRole => (
          <RoleCard key={subRole.id} subRole={subRole} regional={role.dashboard} />
        ))}
        {expandedMenus.some(index => index === i) &&
          role.subRoles
            .slice(4)
            .map(subRole => <RoleCard key={subRole.id} subRole={subRole} regional={role.dashboard} />)}
      </Columns>
      {role.subRoles.length > 4 && (
        <Columns breakpoint="mobile" role="main" className="has-right">
          {expandedMenus.some(index => index === i) ? (
            <Button className="is-link" onClick={() => setExpandedMenus(expandedMenus.filter(index => index !== i))}>
              <Icon path={mdiCloseCircle} size={1} />
              <Translate>stat.home.close</Translate>
            </Button>
          ) : (
            <Button className="is-link" onClick={() => setExpandedMenus([...expandedMenus, i])}>
              <Icon path={mdiChevronRightCircle} size={1} />
              <Translate>stat.home.viewAll</Translate>
            </Button>
          )}
        </Columns>
      )}
    </Section>
  ));
};

const MobileLayout = ({ roles = [] }) => {
  const [openedTab, setOpenedTab] = useState(0);

  return (
    <div className="role-tabs">
      <Tabs fullwidth>
        {roles.map((role, i) => (
          <Tabs.Tab key={i} active={i === openedTab} onClick={() => setOpenedTab(i)}>
            {role.mainRole.name}
          </Tabs.Tab>
        ))}
      </Tabs>
      {roles[openedTab] && <div className="column is-6 role-description">{roles[openedTab].mainRole.description}</div>}
      {roles[openedTab] &&
        roles[openedTab].subRoles.map(subRole => (
          <RoleCard key={subRole.id + Math.random() * 10 + 1} subRole={subRole} regional={roles[openedTab].dashboard} />
        ))}
    </div>
  );
};

const RoleCard = ({ subRole: { id, code, name, dashboards = [] }, regional }) => {
  const [thumbnail, setThumbnail] = React.useState('');
  const history = useHistory();
  const languageCode = useActiveLanguageCode();
  const isTablet = useMedia(['(max-width: 1024px)', '(max-width: 769px)'], [true, false], false);
  const isDisabled = !regional && dashboards.length === 0;
  const loadThumbnail = async () =>
    await import(`../../assets/images/role-thumbnails/${code}.jpg`)
      .catch(err => console.log(err))
      .then(thumb => setThumbnail(thumb ? thumb.default : ''));
  React.useEffect(() => {
    loadThumbnail();
  }, []);

  return (
    <Columns.Column size={isTablet ? 6 : 3}>
      <Card className={`has-image${isDisabled ? ' is-disabled' : ''}`}>
        <div className="card-image">
          <figure className="image is-3by2">
            <img src={thumbnail} alt="" />
          </figure>
        </div>
        <Card.Header.Title
          dangerouslySetInnerHTML={createDangerousHTML(regional ? capitalizeRegionName(name) : name)}
        />
        <Card.Content>
          {dashboards && !regional && (
            <Select
              id="dashboard"
              aria-label="select dashboard"
              options={dashboards}
              placeholder={<Translate>stat.home.chooseDash</Translate>}
              onChange={({ name, id }) => history.push(`/${languageCode}/${getSlug(name)}-${id}`)}
              isSearchable
              getOptionLabel={dashboard => dashboard.name}
              getOptionValue={dashboard => dashboard.id}
              noOptionsMessage={() => <Translate>stat.autocomplete.noMessageText</Translate>}
              isDisabled={isDisabled}
            />
          )}
          {regional && <RegionSelector regionId={id} dashboardId={regional.id} dashboardName={regional.name} />}
        </Card.Content>
      </Card>
    </Columns.Column>
  );
};

const RegionSelector = ({ regionId, dashboardId, dashboardName }) => {
  const fetch = useFetch();
  const history = useHistory();
  const getRegions = useGetRegions();
  const languageCode = useActiveLanguageCode();
  const [regions, setRegions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isGuest = useIsGuest();
  const [, setGuestRegion] = useAsyncRegionChange(dashboardId);

  useEffect(() => {
    if (optionsLoaded) {
      setIsLoading(true);
      handleLoadOptions();
    }
  }, [languageCode]);

  const handleLoadOptions = () => {
    getRegions(regionId)
      .then(opts => {
        setRegions(opts);
        setOptionsLoaded(true);
        setIsLoading(false);
      })
      .catch(err => setIsLoading(false));
  };

  const handleMenuOpen = () => {
    if (!optionsLoaded) {
      setIsLoading(true);
      handleLoadOptions();
    }
  };

  const setRegion = region =>
    fetch(`/v2/dashboard/${dashboardId}/region`, {
      method: 'PATCH',
      body: { language: languageCode, region: parseInt(region.id) },
    }).then(() =>
      history.push(`/${languageCode}/${getSlug(dashboardName)}-${dashboardId}/${getSlug(region.name)}-${region.id}`)
    );

  const handleRegionSelectGuest = region =>
    setGuestRegion(region.mdiChevronRightCircle).then(() =>
      history.push(`/${languageCode}/${getSlug(dashboardName)}-${dashboardId}/${getSlug(region.name)}-${region.id}`)
    );

  return (
    <Select
      id="region"
      aria-label="select region"
      options={regions}
      placeholder={<Translate>stat.home.chooseRegion</Translate>}
      onChange={value => (isGuest ? handleRegionSelectGuest(value) : setRegion(value))}
      isSearchable
      onMenuOpen={handleMenuOpen}
      getOptionLabel={region => region.name}
      getOptionValue={region => region.id}
      noOptionsMessage={() => <Translate>stat.autocomplete.noMessageText</Translate>}
      loadingMessage={() => <Translate>components.Select.Async.loadingMessage</Translate>}
      autoload={false}
      isLoading={isLoading}
    />
  );
};

const useAsyncRegionChange = id => {
  const [guestRegion, setGuestRegion] = useLocalStorage(`v2/dashboard/${id}/region`);
  const setter = x =>
    new Promise(resolve => {
      setGuestRegion(x);
      resolve(x);
    });
  return [guestRegion, setter];
};

export default Home;
