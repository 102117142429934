import { flatten, groupBy, mapKeys, mapValues, omit, sortBy } from 'lodash';
import { useWidget } from '../dashboard/WidgetProvider';
import { useFormatDate } from '../i18n/hooks';

export const combineSeries = (allSeries, widgetFilters) =>
  addExpectationSuffixes(
    Object.values(mergeColumns(groupByAbscissa(flatten(extractSeries(allSeries, widgetFilters)))))
  );

const mergeColumns = rowsGroupedByDate => mapValues(rowsGroupedByDate, mergeObjects);
const mergeObjects = objects => Object.assign({}, ...objects);
const groupByAbscissa = object => groupBy(object, row => row.abscissa || row.date);

const addExpectationSuffixes = series =>
  series.map(row =>
    row.source === 'excel'
      ? mapKeys(row, (value, key) =>
          ['source', 'date', 'abscissa'].includes(key) ? key : appendExpectationSuffix(key)
        )
      : row
  );

export const makeCartesians = (allSeries, widgetFilters, groupByFilterName) => {
  const sortedSeries = allSeries.slice().sort(compareSeries(widgetFilters));
  const statCartesians = groupByFilterName
    ? flatten(Object.values(groupBy(sortedSeries, ({ filters }) => serializeFilters(omit(filters, groupByFilterName)))))
    : sortedSeries;
  const indexedStatCartesians = statCartesians.map(addIndex);
  const excelCartesians = hasExpectations(allSeries) ? indexedStatCartesians.map(addExpectationBoolean) : [];

  return [...indexedStatCartesians, ...excelCartesians].map(({ filters, isExpectation, index }) => {
    const rawDataKey = filters ? makeSeriesDataKey(filters, widgetFilters) : 'value';
    const dataKey = isExpectation ? appendExpectationSuffix(rawDataKey) : rawDataKey;

    return {
      index,
      dataKey,
      filters,
      isExpectation
    };
  });
};

const serializeFilters = (filters = {}) =>
  Object.keys(filters)
    .sort()
    .map(key => filters[key])
    .join(', ');

const compareSeries = widgetFilters => {
  const filterValueOrders = getFilterValueOrders(widgetFilters);

  return (a, b) => {
    const aIds = mapFiltersToIds(a.filters, widgetFilters);
    const bIds = mapFiltersToIds(b.filters, widgetFilters);

    return filterValueOrders
      .map(({ filterId, valueOrder }) => valueOrder[aIds[filterId]] - valueOrder[bIds[filterId]])
      .find(value => value !== 0);
  };
};

const getFilterValueOrders = widgetFilters =>
  widgetFilters.map(filter => ({
    filterId: filter.id,
    valueOrder: makeFilterValueOrderMap(filter.values)
  }));

const makeFilterValueOrderMap = values =>
  values.reduce((result, value, index) => {
    result[value.id] = index;
    return result;
  }, {});

export const useSerializedFilters = () => {
  const { diagram: { filters: widgetFilters = [] } = {} } = useWidget();
  const formatDate = useFormatDate();
  return filters =>
    Object.entries(filters)
      .map(([name, value]) => {
        const filter = findFilterByName(name, widgetFilters);
        return filter && filter.time ? formatDate(value) : value;
      })
      .join(', ');
};

export const getLegendFilter = (filters = []) => filters.filter(filter => filter.type === 'LEGEND').shift();

const makeSeriesDataKey = (filters, widgetFilters) =>
  serializeFilterIdsToDataKey(mapFiltersToIds(filters, widgetFilters));

const addIndex = (object, index) => ({ ...object, index });
const addExpectationBoolean = object => ({ ...object, isExpectation: true });

const extractSeries = (allSeries, widgetFilters) =>
  allSeries.map(({ series, filters }) => series.map(addFiltersAsDataKey(filters, widgetFilters)));

const addFiltersAsDataKey = (filters, widgetFilters) => ({ value, ...row }) => ({
  [filters ? makeSeriesDataKey(filters, widgetFilters) : 'value']: value,
  ...row
});

const mapFiltersToIds = (filters, widgetFilters) =>
  Object.entries(filters)
    .map(([filterName, optionName]) => {
      const filter = findFilterByName(filterName, widgetFilters);
      const option = filter && findOptionByName(optionName, filter.values);
      return [filter, option];
    })
    .filter(([filter, option]) => filter && option)
    .map(([filter, option]) => [filter.id, option.id])
    .reduce(toKeysAndValues, {});

const findFilterByName = (name, widgetFilters) => widgetFilters.find(f => f.name === name);
const findOptionByName = (name, filterOptions) => filterOptions.find(o => o.option === name);

export const hasExpectations = allSeries => allSeries.some(({ series }) => series.some(row => row.source === 'excel'));

export const getFirstSeriesData = series => (series.length > 0 ? series[0].series : []);

export const serializeFilterIdsToDataKey = filters => {
  const entries = Object.entries(filters);
  const sortedEntries = sortBy(entries, ([filterId]) => parseInt(filterId));
  const serialized = sortedEntries.map(([key, value]) => `${key}=${value}`).join(';');
  return serialized;
};

export const deserializeDataKeyToFilterIds = dataKey => {
  const isExpectation = hasExpectationSuffix(dataKey);
  const serialized = isExpectation ? removeExpectationSuffix(dataKey) : dataKey;
  const keyValuePairs = serialized
    .split(';')
    .filter(item => item !== 'value')
    .map(pair => pair.split('=').map(value => parseInt(value)));
  const filters = keyValuePairs.reduce(toKeysAndValues, {});
  return filters;
};

export const appendExpectationSuffix = value => `${value}${EXPECTATION_SUFFIX}`;
export const removeExpectationSuffix = value => value.substring(0, value.length - EXPECTATION_SUFFIX.length);
export const hasExpectationSuffix = value => value.endsWith(EXPECTATION_SUFFIX);
const EXPECTATION_SUFFIX = ';e';

const toKeysAndValues = (result, [key, value]) => {
  result[key] = value;
  return result;
};
