import React from 'react';
import { generatePath, withRouter } from 'react-router';
import { useRegion } from '../../../preferences/dashboard';
import { Translate } from '../../../i18n/LanguageProvider';
import { useIsPersonalDashboard, useWidgets } from '../../../dashboard/DashboardProvider';
import Select from '../../../components/Select';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { getSlug } from '../../../shared/utils';
import { useSlugIds } from '../../../shared/hooks';
import { afterAndIncludingLastSlash } from '../../../shared/constants';

const Breadcrumb = props => {
  const { dashboardName, dashboard, history, match } = props;
  const { type, regions = [] } = dashboard;
  const [region, setRegion] = useRegion();
  const isPersonalDashboard = useIsPersonalDashboard();
  const allWidgets = useWidgets();
  const { graphId } = useSlugIds();
  const widget = allWidgets.find(w => w.id === graphId);

  const onRegionChange = region => {
    const regionSlug = `${getSlug(region.name)}-${region.id}`;
    const nextPath = generatePath(match.path, { ...match.params, regionSlug });
    setRegion(region.id);
    history.replace(nextPath);
  };

  const handleDashboardClick = () => {
    if (!graphId) return;
    const nextPath = generatePath(match.path, { ...match.params });
    history.replace(nextPath.replace(afterAndIncludingLastSlash, ''));
  };

  return (
    <div className="breadcrumb">
      <ul>
        <li>
          <span onClick={() => history.push('/')}>
            <Translate>stat.domains.allDashboards</Translate>
          </span>
          <Icon path={mdiChevronRight} size={0.75} className="is-separator" />
        </li>
        {isPersonalDashboard && (
          <li>
            <span>
              <Translate>stat.header.myDashboard</Translate>
            </span>
          </li>
        )}
        {dashboardName ? (
          <li>
            <span className="link" onClick={handleDashboardClick}>
              {dashboardName}
            </span>
            <Icon path={mdiChevronRight} size={0.75} className="is-separator" />
          </li>
        ) : null}

        {type === 'REGIONAL' && (
          <li>
            <div style={{ width: '200px' }}>
              <Select
                aria-label="Piirkond"
                options={regions}
                onChange={onRegionChange}
                getOptionValue={o => o.id}
                getOptionLabel={o => o.name}
                value={regions.find(r => r.id === region)}
              />
            </div>
            {graphId && <Icon path={mdiChevronRight} size={0.75} className="is-separator" />}
          </li>
        )}
        {graphId && (
          <li>
            <span>{widget ? widget.shortname : '404'}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default withRouter(Breadcrumb);
