import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

export const usePromise = (method, deps = [], initialState) => {
  const [value] = usePromiseWithRefresh(method, deps, initialState);

  return value;
};

export const usePromiseWithRefresh = (method, deps = [], initialState = null) => {
  const [result, setResult] = useState(initialState);
  const isMounted = useIsMounted();

  const callMethod = () => {
    method().then(result => {
      if (isMounted()) setResult(result);
    });
  };

  useEffect(callMethod, deps);

  return [result, callMethod, setResult];
};

export const useIsMounted = () => {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return useCallback(() => isMounted.current, []);
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (e) {}
  }, [storedValue]);

  if (process.env.NODE_ENV === 'development') {
    const prevKey = useRef();

    useEffect(() => {
      if (prevKey.current)
        throw new Error(
          `useLocalStorage hook key changed from "${prevKey.current}" to "${key}". ` +
            'This is not allowed, maybe you forgot "key" property on the component?'
        );

      prevKey.current = key;
    }, [key]);
  }

  return [storedValue, setStoredValue];
};

export const useYAxisWidth = (deps = []) => {
  const [yAxisWidth, setYAxisWidth] = useState();
  const ref = useRef();

  const setNode = React.useCallback(node => {
    ref.current = node;
    updateSize();
  }, []);

  const updateSize = () => {
    if (!ref.current) return;

    const yAxisTicks = [...ref.current.querySelectorAll('.recharts-yAxis .recharts-cartesian-axis-tick > text')];
    const widths = yAxisTicks.map(tick => tick.getBBox().width);

    setTimeout(() => setYAxisWidth(yAxisTicks.length > 0 ? Math.max(...widths) + 10 : undefined), 10);
  };

  useLayoutEffect(updateSize, deps);

  return [yAxisWidth, setNode];
};

export const useLayoutReady = (predicate, callback, deps) => {
  const layoutReadyCalled = useRef(false);
  useLayoutEffect(() => {
    if (predicate() && !layoutReadyCalled.current) {
      callback();
      layoutReadyCalled.current = true;
    }
  }, deps);
};

export const useDeferredState = initialState => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  return [state, setState];
};

export const useMedia = (queries, values, defaultValue) => {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));
  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, []);

  return value;
};

export const useSlugIds = () => {
  const { params } = useRouteMatch();
  const { dashboardSlug, regionSlug, graphSlug } = params;

  const graphId = getIdFromSlug(graphSlug);
  const dashboardId = getIdFromSlug(dashboardSlug);
  const regionId = getIdFromSlug(regionSlug);

  return { graphId, dashboardId, regionId };
};

const getIdFromSlug = slug => {
  if (!slug) return null;
  const idMatch = slug && slug.match(/-(\d+$)/);
  if (idMatch && idMatch[1]) {
    return parseInt(idMatch[1]);
  }
  return null;
};
