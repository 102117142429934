import React from 'react';
import { useHistory } from 'react-router-dom';
import { Translate, useActiveLanguageCode } from '../../i18n/LanguageProvider';
import { Button, Modal, Heading } from 'react-bulma-components';
import { useSeenNews } from '../../auth/UserProvider';

const NewFeatureInfoModal = () => {
  const history = useHistory();
  const lang = useActiveLanguageCode();
  const [showNewFeatureModal, setShowNewFeatureModal] = useSeenNews();
  const onClose = () => setShowNewFeatureModal();
  const onNavigate = to => setShowNewFeatureModal().then(() => history.push(to));
  return (
    <>
      {showNewFeatureModal && (
        <Modal show onClose={onClose} closeOnBlur={true} className="new-feature-modal">
          <Modal.Card>
            <Modal.Card.Head showClose={false}>
              <Heading renderAs="h3">
                <Translate>stat.myDashboard.newFeatureHeader</Translate>
              </Heading>
            </Modal.Card.Head>
            <Modal.Card.Body>
              <span>
                <Translate>stat.myDashboard.newFeatureText</Translate>
              </span>
            </Modal.Card.Body>
            <Modal.Card.Foot>
              <Button
                type="button"
                aria-label="confirm"
                onClick={() => onNavigate(`/${lang}`)}
                className="is-primary is-pulled-right"
              >
                <Translate>stat.domains.allDashboards</Translate>
              </Button>
              <Button
                type="button"
                aria-label="confirm"
                onClick={() => onNavigate(`/${lang}/dashboard/me`)}
                className="is-primary is-pulled-right"
              >
                <Translate>stat.myDashboard.navigateTo</Translate>
              </Button>
            </Modal.Card.Foot>
          </Modal.Card>
        </Modal>
      )}
    </>
  );
};

export default NewFeatureInfoModal;
