import React, { createContext, useContext, useState } from 'react';

const UIContext = createContext({});

const UIProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const value = { isDrawerOpen, setIsDrawerOpen, isMenuOpen, setIsMenuOpen };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useDrawer = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useContext(UIContext);
  const toggleDrawer = () => {
    window.scrollTo(0, 0);
    setIsDrawerOpen(!isDrawerOpen);
  };
  const closeDrawer = () => setIsDrawerOpen(false);
  const openDrawer = () => setIsDrawerOpen(true);

  return { isDrawerOpen, openDrawer, closeDrawer, toggleDrawer };
};

export const useMenu = () => {
  const { isMenuOpen, setIsMenuOpen } = useContext(UIContext);
  const toggleMenu = () => {
    window.scrollTo(0, 0);
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);

  return { isMenuOpen, openMenu, closeMenu, toggleMenu };
};

export default UIProvider;
