import { flatten, groupBy, mapValues, omit, sortBy } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useFetch } from '../api/ApiProvider';
import { DashboardContext, useIsPersonalDashboard } from '../dashboard/DashboardProvider';
import { useWidget, useWidgetId, WidgetContext } from '../dashboard/WidgetProvider';
import { useLocalStorage } from '../shared/hooks';
import { appendExpectationSuffix, deserializeDataKeyToFilterIds, hasExpectationSuffix } from '../shared/series';
import { useTranslate } from '../i18n/LanguageProvider';
import { useSelectedWidgets } from './dashboard';

const nop = () => {};

const WidgetPreferencesContext = React.createContext([undefined, nop]);

/* API preferences */

export const ApiWidgetPreferencesProvider = ({ children }) => {
  const fetch = useFetch();
  const translate = useTranslate();

  const { dashboardId } = useContext(DashboardContext);
  const widgetId = useWidgetId();
  const { refreshWidget } = useContext(WidgetContext);
  const [storedWidgetIds, setStoredSelectedWidgets] = useSelectedWidgets();
  const isPersonalDashboard = useIsPersonalDashboard();

  const preferences = useWidget().preferences || EMPTY_OBJECT;

  const setPreferences = updater =>
    fetch(`/dashboard/${dashboardId}/widget/${widgetId}/preferences`, {
      method: 'PATCH',
      body: updater(preferences),
    }).then(refreshWidget);

  const setWidgetPinned = async (widgetId, { pinned }) => {
    return fetch(`/dashboard/${dashboardId}/widget/${widgetId}/pin`, {
      method: 'PATCH',
      body: { pinned },
      successNotification: pinned ? translate('api.pinSuccess') : translate('api.unpinSuccess'),
      errorNotification: pinned ? translate('api.pinFailed') : translate('api.unpinFailed'),
    }).then(() => {
      if (!pinned && isPersonalDashboard) setStoredSelectedWidgets(storedWidgetIds.filter(id => id !== widgetId));
      else refreshWidget();
    });
  };

  const value = {
    preferences: addMissingTargetToLegends(preferences),
    setPreferences: setPreferences,
    pinned: preferences.pinned || false,
    setPinned: setWidgetPinned,
  };

  return <WidgetPreferencesContext.Provider value={value}>{children}</WidgetPreferencesContext.Provider>;
};

/* TODO api should probably do this */
const addMissingTargetToLegends = ({ graphs = EMPTY_ARRAY, ...preferences }) => ({
  ...preferences,
  graphs: graphs.map(({ legends, ...graph }) => ({
    ...graph,
    legends: legends.map(({ target, values }) => ({
      target: !!target,
      values,
    })),
  })),
});

/* Local storage preferences */

export const LocalStorageWidgetPreferencesProvider = ({ widgetId, children }) => {
  const [widgets, setWidgets] = useContext(LocalStorageAllWidgetsPreferencesContext);

  const value = {
    preferences: widgets[widgetId] || EMPTY_OBJECT,
    setPreferences: updater =>
      setWidgets(prevState => ({
        ...prevState,
        [widgetId]: updater(prevState[widgetId]),
      })),
  };

  return <WidgetPreferencesContext.Provider value={value}>{children}</WidgetPreferencesContext.Provider>;
};

const LocalStorageAllWidgetsPreferencesContext = React.createContext();

export const LocalStorageAllWidgetsPreferencesProvider = ({ children }) => {
  const { dashboardId } = useContext(DashboardContext);

  const value = useLocalStorage(`v2/dashboard/${dashboardId}/widgets`, {});

  return (
    <LocalStorageAllWidgetsPreferencesContext.Provider value={value}>
      {children}
    </LocalStorageAllWidgetsPreferencesContext.Provider>
  );
};

export const useAllWidgetPreferences = () => useContext(LocalStorageAllWidgetsPreferencesContext);

/* Fixed preferences */

export const FixedWidgetPreferencesProvider = ({ preferences, children }) => (
  <WidgetPreferencesContext.Provider value={{ preferences: preferences, setPreferences: nop }}>
    {children}
  </WidgetPreferencesContext.Provider>
);

export const InMemoryWidgetPreferencesProvider = ({ children }) => {
  const [value, setValue] = React.useState({});

  return (
    <WidgetPreferencesContext.Provider value={{ preferences: value, setPreferences: setValue }}>
      {children}
    </WidgetPreferencesContext.Provider>
  );
};

/* hooks */

export const useChartType = () => {
  const widget = useWidget();

  const [{ defaultGraphType: chartType = widget && widget.graphType } = {}, setPreferences] = useWidgetPreferences();
  const setChartType = chartType => setPreferences(preferences => ({ ...preferences, defaultGraphType: chartType }));

  return [chartType, setChartType];
};

export const useDisabledSeries = () => {
  const [legends, setLegends] = useLegends();
  const disabledLegends = useMemo(() => groupBy(legends, serializeDisabledSeries), [legends]);

  const isSeriesDisabled = dataKey => !!disabledLegends[dataKey];
  const setIsSeriesDisabled = (dataKey, isDisabled) =>
    setLegends(legends =>
      isDisabled
        ? [...legends, makeDisabledLegendDto(dataKey)]
        : flatten(Object.values(omit(groupBy(legends, serializeDisabledSeries), dataKey)))
    );

  return [isSeriesDisabled, setIsSeriesDisabled];
};

const serializeDisabledSeries = ({ values, target }) => {
  const serialized =
    sortBy(values, 'filter')
      .map(({ filter, value }) => `${filter}=${value}`)
      .join(';') || 'value';

  return target ? appendExpectationSuffix(serialized) : serialized;
};

const makeDisabledLegendDto = dataKey => ({
  target: hasExpectationSuffix(dataKey),
  values: Object.entries(deserializeDataKeyToFilterIds(dataKey)).map(([filter, value]) => ({
    filter: parseInt(filter),
    value,
  })),
});

const useLegends = () => {
  const [{ legends = EMPTY_ARRAY }, setPreferences] = useCurrentChartTypePreferences();
  const setLegends = updater =>
    setPreferences(({ legends = [], ...preferences }) => ({
      ...preferences,
      legends: updater(legends),
    }));

  return [legends, setLegends];
};

export const useFilterValue = () => {
  const [filters, setFilters] = useFilters();

  const getFilterValue = filterId => {
    const filter = filters.find(f => f.filter === filterId);
    return filter && filter.values;
  };
  const setFilterValue = (filterId, optionIds, meta) => {
    setFilters(filters => [
      ...filters.filter(f => f.filter !== filterId),
      {
        filter: filterId,
        values: optionIds,
        meta,
      },
    ]);
  };

  return [getFilterValue, setFilterValue];
};

export const useFilters = () => {
  const [{ filters = EMPTY_ARRAY } = {}, setPreferences] = useCurrentChartTypePreferences();

  const setFilters = updater =>
    setPreferences(({ filters = [], ...preferences }) => ({
      ...preferences,
      filters: updater(filters),
    }));

  return [filters, setFilters];
};

export const useCurrentChartTypePreferences = () => {
  const [chartType] = useChartType();
  const [{ graphs = [] } = {}, setPreferences] = useWidgetPreferences();

  const chartTypePreferences = graphs.find(type => type.graphType === chartType) || EMPTY_OBJECT;
  const setChartTypePreferences = updater =>
    setPreferences(({ graphs = [], ...preferences } = {}) => ({
      ...preferences,
      defaultGraphType: chartType,
      graphs: [
        ...graphs.filter(type => type.graphType !== chartType),
        { ...updater(chartTypePreferences), graphType: chartType },
      ],
    }));

  return [chartTypePreferences, setChartTypePreferences];
};

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const useWidgetPreferences = () => {
  const { preferences = [], setPreferences = nop } = useContext(WidgetPreferencesContext);

  return [preferences, setPreferences];
};

export const useWidgetPinned = () => {
  const { pinned, setPinned } = useContext(WidgetPreferencesContext);

  return [pinned, setPinned];
};

export const useWidgetQuery = () => {
  const [chartType] = useChartType();
  const [filters] = useFilters();

  return {
    ...filters.reduce((result, { filter, values }) => {
      result[filter] = values.join(',');
      return result;
    }, {}),
    graphType: chartType,
  };
};

export const clearWidgetPreferences = () =>
  Object.keys(localStorage)
    .filter(key => key.match(/^(?:v2\/)?dashboard\/[0-9]+\/widgets\/[0-9]+/))
    .forEach(key => localStorage.removeItem(key));

// TODO this is not a good solution, but we will go with it for now
// as behaviour regarding guest users is likely to change in the future
export const clearRegionalFilterValues = prevState =>
  mapValues(prevState, ({ graphs = [], ...preferences }) => ({
    ...preferences,
    graphs: graphs.map(({ filters = [], ...graph }) => ({
      ...graph,
      filters: filters.filter(({ meta = {} }) => !meta.isRegional),
    })),
  }));
