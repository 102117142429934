import { utils as xlsxUtils, writeFile } from 'xlsx';
import FileSaver from 'file-saver';

export const tableToSheet = async table => {
  return xlsxUtils.json_to_sheet(table, { skipHeader: true });
};

export const downloadAsExcelSpreadsheet = async (sheet, fileName) => {
  const workbook = await sheetToWorkbook(sheet, fileName);
  writeFile(workbook, `${fileName}.xlsx`);
};

export const sheetToWorkbook = async (sheet, sheetName) => {
  const workbook = xlsxUtils.book_new();
  xlsxUtils.book_append_sheet(workbook, sheet, sheetName.substr(0, 31));
  return workbook;
};

export const downloadAsCsv = async (sheet, fileName) => {
  const bom = '\ufeff';
  const csv = xlsxUtils.sheet_to_csv(sheet, { strip: true });
  const blob = new Blob([bom + csv], { type: 'text/csv, charset=UTF-8' });

  FileSaver.saveAs(blob, `${fileName}.csv`);
};

export const downloadAsJson = async (data, fileName) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json, charset=UTF-8' });

  FileSaver.saveAs(blob, `${fileName}.json`);
};

export const downloadAsFile = async (data, fileName) => {
  const blob = new Blob([data]);

  FileSaver.saveAs(blob, `${fileName}`);
};

export const concatTables = (a, b) => (a.length > 0 ? [...a, ...prefixWithEmptyRow(b)] : b);

export const hideIfEmptyBody = rows => (rows.length > 1 ? rows : []);

export const addHeaderAndFooter = (table, header, footer) => [
  [header],
  ...prefixWithEmptyRow(table),
  ...prefixWithEmptyRow([[footer]]),
];

export const prefixWithEmptyRow = rows => (rows.length > 0 ? [[], ...rows] : []);

export const rowIsStat = row => row.source === 'stat';
export const rowIsExcel = row => row.source === 'excel';
export const cartesianIsNotExpectation = ({ isExpectation }) => !isExpectation;
export const cartesianIsExpectation = ({ isExpectation }) => isExpectation;

export const tryToMap = mapFunction => value => {
  try {
    return mapFunction(value);
  } catch (e) {
    return value;
  }
};

export const and =
  (filterA, filterB) =>
  (...args) =>
    filterA(...args) && filterB(...args);
