import React from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar } from 'react-bulma-components';
import { useTranslate, Translate } from '../../../i18n/LanguageProvider';
import Select from '../../../components/Select/';

const NavItemsLinks = () => {
  const translate = useTranslate();
  const history = useHistory();
  const linkOptions = translate('stat.header.navItems');
  const onChange = link => (link.href === '/' ? history.push(link.href) : (window.location = link.href));

  return (
    <>
      <Navbar.Item>
        <Translate>stat.name</Translate>:
      </Navbar.Item>
      <Navbar.Item style={{ width: '150px', display: 'inline-block' }}>
        <Select
          menuPortalTarget={document.body}
          aria-label="links"
          options={linkOptions}
          value={{ name: translate('stat.header.dashboards') }}
          onChange={onChange}
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => opt.href}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 2 }),
            menuList: base => ({ ...base, maxHeight: 'auto' }),
            option: base => ({ ...base, cursor: 'pointer' })
          }}
        />
      </Navbar.Item>
    </>
  );
};

export default NavItemsLinks;
