import { createFactory } from 'react';
import slugify from 'slugify';
import xss from 'xss';

export const wrapToArray = value => (value instanceof Array ? value : [value]);

export const nest = (...Components) => {
  const factories = Components.map(createFactory);

  return ({ children, ...props }) => factories.reduceRight((child, factory) => factory(props, child), children);
};

export const moveArrayItem = (arr, fromIndex, toIndex) => {
  const clone = arr.slice();
  const [element] = clone.splice(fromIndex, 1);
  clone.splice(toIndex, 0, element);
  return clone;
};

export const updateArrayItem = (array, index, updater) =>
  array.map((item, anIndex) => (index === anIndex ? updater(item) : item));

export const removeArrayItem = (array, index) => array.filter((item, anIndex) => index !== anIndex);

export const coalesceStrings = (a, b) => (a === undefined ? b : a);

export const isMobile = () => {
  const ua = navigator.userAgent;
  const isAndroid = Boolean(ua.match(/Android/i));
  const isIos = Boolean(ua.match(/iPhone|iPad|iPod/i));
  const isOpera = Boolean(ua.match(/Opera Mini/i));
  const isWindows = Boolean(ua.match(/IEMobile/i));

  return isAndroid || isIos || isOpera || isWindows;
};

export const isTouchDevice = () => {
  return 'ontouchstart' in document.documentElement;
};

export const isAppEmbedded = window.location !== window.parent.location;

export const apply = (valueOrFunction, ...args) =>
  typeof valueOrFunction === 'function' ? valueOrFunction(...args) : valueOrFunction;

export const createDangerousHTML = text => {
  return { __html: xss(text) };
};

export const capitalizeRegionName = name =>
  name
    .toLowerCase()
    .replace(/(^|[\s-])\S/g, match => match.toUpperCase())
    .replace('Maakond', 'maakond')
    .replace('Country', 'country');

export const getSlug = str => slugify(str, { lower: true });

export const isDev =
  process.env.NODE_ENV === 'development' ||
  ['localhost', 'arendus.juhtimislauad.stat.ee'].includes(window.location.hostname);
