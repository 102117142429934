import React from 'react';
import { Form } from 'react-bulma-components';
import './styles.scss';

const Checkbox = ({ children, ...props }) => (
  <Form.Checkbox {...props}>
    {' '}
    <span></span> {children}
  </Form.Checkbox>
);

export default Checkbox;
