import qs from 'qs';
import React, { useContext, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router';
import { useLocalStorage } from '../shared/hooks';

const TokenContext = React.createContext([undefined, () => {}]);

const TokenProvider = ({ location: { search, pathname }, history, ...props }) => {
  const [token, setToken] = useLocalStorage('token', 'guest');
  const [location] = useLocalStorage('location');

  useEffect(() => {
    const { idCode, token } = qs.parse(search, { ignoreQueryPrefix: true });

    if (idCode && token) {
      setToken(idCodeAndTokenToAuthToken(idCode, token));
      history.replace(location);
    }
  }, [pathname, search]);

  const value = [token, setToken];

  return <TokenContext.Provider value={useMemo(() => value, value)} {...props} />;
};

export const idCodeAndTokenToAuthToken = (idCode, token) => btoa(`${idCode}:${token}`);

export const useToken = () => useContext(TokenContext);

export default withRouter(TokenProvider);
