import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import etLocaleData from 'react-intl/locale-data/et';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ApiProvider } from '../../api/ApiProvider';
import TokenProvider from '../../auth/TokenProvider';
import UserProvider, { useIsAdmin, useIsGuest } from '../../auth/UserProvider';
import ConnectedIntlProvider from '../../i18n/ConnectedIntlProvider';
import LanguageProvider, { LanguageContext, Title, useTranslate } from '../../i18n/LanguageProvider';
import UIProvider from '../../dashboard/UIProvider';
import { nest } from '../../shared/utils';
import Home from '../Home/Home';
import NewFeatureInfoModal from '../Home/NewFeatureInfoModal';
import { useLocalStorage } from '../../shared/hooks';
import { ToastProvider } from '../../notifications';
import { SLUG } from '../../shared/constants';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const Admin = lazy(() => import('../../admin/Admin'));
const EmbeddedWidget = lazy(() => import('../../embed/EmbeddedWidget'));

addLocaleData(etLocaleData);
addLocaleData(enLocaleData);

const AppWithProviders = () => (
  <Providers>
    <App />
  </Providers>
);

const HTML5DragDropProvider = props => <DndProvider backend={MultiBackend} options={HTML5toTouch} {...props} />;
const Router = props => <BrowserRouter basename={process.env.PUBLIC_URL} {...props} />;
const Providers = nest(
  HTML5DragDropProvider,
  Router,
  LanguageProvider,
  ConnectedIntlProvider,
  TokenProvider,
  UserProvider,
  UIProvider,
  ApiProvider,
  CookiesProvider,
  ToastProvider
);

const App = () => {
  const isAdmin = useIsAdmin();
  const { activeLanguageCode, setActiveLanguageCode } = useContext(LanguageContext);
  const history = useHistory();
  const translate = useTranslate();
  const isGuest = useIsGuest();
  const [, setLocation] = useLocalStorage('location');
  const { pathname } = history.location;

  useEffect(() => {
    setLocation(pathname);
  }, [pathname]);

  const oldDashboardRoute = useRouteMatch('/dashboard/:id(\\d+)');
  const oldMyDashboardRoute = useRouteMatch('/dashboard/me');

  if (oldDashboardRoute) {
    const { id } = oldDashboardRoute.params;
    return <Redirect to={`/${activeLanguageCode}/dashboard-${id}`} />;
  }

  if (oldMyDashboardRoute) {
    return <Redirect to={translate('stat.myDashboardPath')} />;
  }

  const languageMatches = pathname.match(/^\/(en|et)/);
  const langInUrl = languageMatches ? languageMatches[1] : null;
  const isEmbedPath = !!pathname.match(/^\/embed/);

  if (langInUrl && !isEmbedPath) {
    setActiveLanguageCode(langInUrl);
  } else if (!pathname.startsWith(`/${activeLanguageCode}`) && !isEmbedPath) {
    history.push(`/${activeLanguageCode}${pathname}`.replace(/\/$/, ''));
  }

  return (
    <div className="App">
      <Title />
      <Suspense fallback={null}>
        <Switch>
          <Route exact path="/embed" component={EmbeddedWidget} />
          <Switch>
            {isAdmin && <Route path="/:lang/admin" component={Admin} />}
            {!isGuest && (
              <Route path={translate('stat.myDashboardPath')} render={props => <Dashboard {...props} personal />} />
            )}
            <Route
              path={`/:lang/${SLUG.DASHBOARD}/${SLUG.REGION}/${SLUG.GRAPH}`}
              render={props => <Dashboard {...props} />}
            />
            <Route path={`/:lang/${SLUG.DASHBOARD}/${SLUG.GRAPH}`} render={props => <Dashboard {...props} />} />
            <Route path={`/:lang/${SLUG.DASHBOARD}/${SLUG.REGION}?`} render={props => <Dashboard {...props} />} />
            <Home />
          </Switch>
        </Switch>
        <NewFeatureInfoModal />
      </Suspense>
    </div>
  );
};

export default AppWithProviders;
