import React, { useContext } from 'react';
import { LanguageContext, useTranslate } from '../../../i18n/LanguageProvider';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

const LangSelector = () => {
  const { activeLanguageCode, setActiveLanguageCode } = useContext(LanguageContext);
  const history = useHistory();
  const { params, path } = useRouteMatch();
  const translate = useTranslate();

  const onLangChange = lang => {
    const { pathname } = history.location;
    let nextPath;

    if (pathname === translate('stat.myDashboardPath')) {
      nextPath = translate('stat.myDashboardPath', { languageCode: lang });
    } else {
      nextPath = generatePath(path, { ...params, lang });
    }

    history.push(nextPath);
    setActiveLanguageCode(lang);
  };

  const langOptions = [{ name: 'EST', value: 'et' }, { name: 'ENG', value: 'en' }];

  return (
    <>
      {langOptions.map(opt => (
        <button
          key={opt.value}
          className={`button is-small is-primary ${opt.value === activeLanguageCode ? 'is-active' : ''}`}
          onClick={() => onLangChange(opt.value)}
        >
          {opt.name}
        </button>
      ))}
    </>
  );
};

export default LangSelector;
