const setupEventListeners = () => {
  document.addEventListener('dashboardopened', event => {
    const { dashboard } = event.detail;

    window.dataLayer?.push({
      event: 'dashboardopened',
      dashboard,
      widget: null,
    });
  });

  document.addEventListener('widgetexpanded', event => {
    const {
      widget: { series, ...widget },
    } = event.detail;

    window.dataLayer?.push({
      event: 'widgetexpanded',
      widget,
    });
  });
};

if (process.env.NODE_ENV === 'production') setupEventListeners();
