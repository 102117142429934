import React from 'react';
import Icon from '@mdi/react';
import { mdiPin, mdiArrowAll } from '@mdi/js';
import cookies from '../assets/documents/cookies_en.pdf';
import terms from '../assets/documents/terms_en.pdf';
const translations = {
  'stat.myDashboardPath': '/en/my-dashboard',
  'stat.url.dashboard': 'dashboard',
  'stat.graph': 'graph',
  'stat.lang.et': 'Estonian',
  'stat.lang.en': 'English',
  'stat.app.title': 'Statistics dashboards',
  'stat.header.options': 'Graph selection',
  'stat.header.dashboards': 'Dashboards',
  'stat.header.dashboards.description': 'A quick and convenient way to get visualised data! Download! Share! Use!',
  'stat.header.dashboards.description.loginNotification': 'Log in and create your own dashboard.',
  'stat.header.language': 'Language',
  'stat.header.navItems': () => [
    { name: 'Webpage', href: 'https://www.stat.ee/en/' },
    { name: 'Statistical database', href: 'https://andmed.stat.ee/en/stat' },
    { name: 'Earnings application', href: 'https://palgad.stat.ee/' },
    { name: 'Foreign trade application', href: 'https://valiskaubandus.stat.ee/profile/country/ee/?locale=en' },
    { name: 'Tree of Truth', href: 'https://tamm.stat.ee/?lang=en' },
  ],
  'stat.header.feedback': 'Feedback',
  'stat.header.myDashboard': 'My dashboard',
  'stat.header.logout': 'Log out',
  'stat.footer.statInfo': 'Statistics info',
  'stat.home.dashboard': 'Dashboards',
  'stat.home.chooseDash': 'Select dashboard',
  'stat.home.chooseRegion': 'Select region',
  'stat.home.confirm': 'Confirm',
  'stat.home.area': 'Area',
  'stat.home.viewAll': 'View all',
  'stat.home.close': 'Close',
  'stat.share.download': 'Download',
  'stat.share': 'Share',
  'stat.share.link': 'Share link',
  'stat.share.link.tooltip': 'Copy link to share the interactive graph',
  'stat.share.embed': 'Embed graph',
  'stat.share.embed.tooltip': 'Use this code to embed the graph into your website.',
  'stat.share.graph': 'Share graph',
  'stat.share.graph.tooltip': 'Copy link to share your graph',
  'stat.share.print': 'Print',
  'stat.share.iePrint': 'To print this graph please download it as an image',
  'stat.share.pin': 'Add to my dashboard',
  'stat.share.unpin': 'Remove from my dashboard',
  'stat.share.pinGuest': 'To add this graph to your dashboard please log in',
  'stat.chart.source': 'Source',
  'stat.chart.expectation': 'reference level',
  'stat.chart.closeDetail': 'Close detail view',
  'stat.chart.closeThumb': 'Remove selection',
  'stat.chart.moveThumb': 'Move selection',
  'stat.chart.line': 'Line chart',
  'stat.chart.bar': 'Bar chart',
  'stat.chart.pie': 'Pie chart',
  'stat.chart.vertical': 'Vertical bar chart',
  'stat.chart.area': 'Area chart',
  'stat.chart.radar': 'Radar chart',
  'stat.chart.treemap': 'Treemap',
  'stat.chart.map': 'Map',
  'stat.chart.pyramid': 'Population pyramid',
  'stat.chart.stacked': 'Stacked bar chart',
  'stat.chart.info': 'Information',
  'stat.domains.activateAll': 'select all',
  'stat.domains.activateAllUC': 'Select all',
  'stat.domains.deactivateAll': 'deselect all',
  'stat.domains.deactivateAllUC': 'Deselect all',
  'stat.domains.confirm': 'Confirm graph selection',
  'stat.domains.chooseFromDash': 'Select graphs by domain',
  'stat.domains.chooseFromAll': ({ activateAll, deactivateAll }) => (
    <>
      Select from all graphs, {activateAll} or {deactivateAll}
    </>
  ),
  'stat.domains.or': ' or ',
  'stat.domains.allDashboards': 'All dashboards',
  'stat.widgets.options': 'Open graph selection',
  'stat.widgetProvider.error': 'There was a problem loading the widget.',
  'stat.widgetProvider.error.hiddenOrDeleted.short': 'The graph cannot be displayed.',
  'stat.widgetProvider.error.hiddenOrDeleted': () => (
    <span>
      The graph cannot be displayed. See{' '}
      <a href="https://juhtimislauad.stat.ee/" rel="noopener noreferrer" target="_blank">
        juhtimislauad.stat.ee
      </a>
    </span>
  ),
  'stat.widgetProvider.noData': () => (
    <>
      <p>No data available.</p>
      <p>
        <small>Try again by changing filter values.</small>
      </p>
    </>
  ),
  'stat.widgetInfo.moreInfo': 'More info',
  'stat.widgetInfo.explanation': 'Definition',
  'stat.widgetInfo.notes': 'Notes',
  'stat.widgetInfo.methods': 'Definitions and Methodology',
  'stat.widgetInfo.baseData': 'Base data in the statistics database',
  'stat.widgetInfo.lastUpdate': 'Last updated',
  'stat.widgetInfo.statJob': 'Statistical activity',
  'stat.login.enter': 'Log in',
  'stat.login.enterGuest': 'Continue as a visitor',
  'stat.login.title': 'Statistics dashboards',
  'stat.login.intro': () => {
    return (
      <>
        <p>
          What is the environmental, demographic, social and economic situation in Estonia? What is changing and how?
          Statistics provide an answer.
        </p>
        <p>
          The statistics dashboard is a convenient working environment, where important official statistics have been
          visualised and are just a few clicks away.
        </p>
        <p>The statistics dashboard helps you make informed decisions!</p>
        <p>
          By entering, you agree to the{' '}
          <a href={terms} rel="noopener noreferrer" target="_blank">
            terms
          </a>
          .
        </p>
      </>
    );
  },
  'stat.guestModal.title': 'Are you sure?',
  'stat.guestModal.modalText': 'If you log in as a visitor, your selections will not be saved.',
  'stat.logout.confirm': 'Are you sure you want to log out?',
  'stat.logout.confirmGuest':
    'If you are logged in as a visitor, your selections will not be saved. Are you sure you wish to log out?',
  'stat.guest': 'Visitor',
  'stat.firstVisit.hello': 'Hello',
  'stat.firstVisit.longText':
    'Esmakordselt juhtimislaual palume sul seadistada kuvamoodulite valik oma juhtimilauale. Valikud salvestatakse järgnevateks külastusteks, neid saad taas avada ja muuta(en)',
  'stat.firstVisit.choices': 'Valikute(en)',
  'stat.firstVisit.menu': 'menüüpunkti alt.(en)',
  'stat.firstVisit.cookies': () => {
    return (
      <p>
        This website uses cookies. By using the website you agree to our use of cookies. More information about cookies
        is available{' '}
        <a href={cookies} rel="noopener noreferrer" target="_blank">
          here.
        </a>{' '}
        By entering, you agree to the{' '}
        <a href={terms} rel="noopener noreferrer" target="_blank">
          terms
        </a>
        .
      </p>
    );
  },
  'stat.cookie.compliance.heading': 'Cookie usage',
  'stat.cookie.compliance.message': () => (
    <p>
      This website uses cookies to ensure a more convenient and personalised use of the site.{' '}
      <a href="https://www.stat.ee/et/statistikaamet/andmekaitse" rel="noopener noreferrer" target="_blank">
        More information
      </a>
      .
    </p>
  ),
  'stat.cookie.compliance.accept': 'Accept',
  'stat.cookie.compliance.reject': 'Reject',
  'stat.cookie.compliance.settings': 'Cookie settings',
  'stat.name': 'Statistics Estonia',
  'stat.footer.dashboard': ', dashboards',
  'stat.autocomplete.noMessageText': 'No result found',
  'stat.myDashboard.readMore': 'Instructions',
  'stat.myDashboard.collapseText': () => {
    return (
      <div>
        <p>My dashboard allows you to save graphs from different existing dashboards to your own personal dashboard.</p>
        <ul style={{ listStyleType: 'disc' }}>
          <li>
            To add a graph, find it on an existing dashboard and push the pin button in the top right corner of the
            graph <Icon color="rgb(0,0,0)" path={mdiPin} size={1} />
          </li>
          <li>
            To re-arrange the order of the graphs on your dashboard use the drag-and-drop icon{' '}
            <Icon color="rgb(0,0,0)" path={mdiArrowAll} size={1} />
          </li>
          <li>
            To remove a graph, find it on your dashboard or an existing one and click the pin icon{' '}
            <Icon color="white" path={mdiPin} size={1.3} className="pin-icon-instructions" />
          </li>
        </ul>
      </div>
    );
  },
  'stat.myDashboard.newFeatureText': () => {
    return (
      <div>
        <p>
          The Statistics Dashboards application now has a functionality created for the purpose of allowing users to
          have a better overview of the statistics most relevant to their needs. This means that you now have the option
          of gathering specific graphs from different pre-existing dashboards and pinning them to your personal
          dashboard.
        </p>
        <p>
          You will be able to find this configurable dashboard by clicking on your name on the top right side of the
          navigation bar and clicking on 'My dashboard'.{' '}
        </p>
        <p>
          Instructions on how to add relevant graphs from different pre-existing dashboards to your own can be found in
          the 'Instructions' section on the 'My dashboard' page.
        </p>
        <p>Would you like to go to the 'My dashboard' page now or view the selection of pre-existing dashboards?</p>
      </div>
    );
  },
  'stat.myDashboard.newFeatureHeader': 'Create your own dashboard!',
  'stat.myDashboard.navigateTo': 'My dashboard',
  'stat.filters.maximumOptionsMessage': ({ numberOfOptions }) =>
    numberOfOptions === 1 ? '1 value can be selected' : `A maximum of ${numberOfOptions} values can be selected`,
  'stat.embed.widgetUrl': ({ widgetUrl }) => (
    <>
      See interactive graph{' '}
      <a href={widgetUrl} target="_blank" rel="noopener noreferrer">
        juhtimislauad.stat.ee
      </a>
    </>
  ),
  'components.Select.MenuList.selectAll': 'Select all visible options',
  'components.Select.MenuList.placeholder': 'Select..',
  'components.Select.MenuList.placeholderRegion': 'Select region',
  'components.Select.Placeholder.choose': 'Select ',
  'components.Select.Async.loadingMessage': 'Loading options',
  'components.Chart.week': ({ weekNr, year }) => `Week ${weekNr} ${year}`,
  'components.Chart.weekLong': ({ weekNr, startDate, endDate }) => `Week ${weekNr} (${startDate}–${endDate})`,
  'api.operationPending': 'We are processing your request, please hold on..',
  'api.operationFailed': 'Request failed.',
  'api.pinSuccess': 'Pinned successfully',
  'api.unpinSuccess': 'Unpinned successfully',
  'api.pinFailed': 'Pin unsuccessful',
  'api.unpinFailed': 'Unpin unsuccessful',
  'api.myDashboard.notExist': 'Add graphs to create your own dashboard',
  'auth.sessionExpired': "Your session has expired, you're now logged out.",
  'auth.loggedOut': 'You have successfully logged out.',
  'error.dashboard_is_not_existing': 'Dashboard not found',
  'error.dashboard_is_hidden': 'Dashboard is hidden',
  'error.dashboard_must_be_regional': 'Dashboard must be regional',
  'error.my_dashboard_does_not_exist': 'Dashboard not found',
  'error.my_dashboard_must_have_user': 'Dashboard must have a user',
  'error.widget_is_not_existing': 'Widget not found',
  'error.widget_is_not_existing_or_added_to_dashboard': 'Widget does not exist or it is already added to a dashboard',
  'error.widget_is_hidden': 'Widget is hidden',
  'error.user_widget_is_not_existing': 'Widget not found',
  'error.uploaded_file_has_no_name': 'Uploaded file has no name',
  'error.uploaded_file_has_no_extension': 'Uploaded file has no file extension',
  'error.uploaded_file_is_not_excel': 'Uploaded file is not an Excel file',
  'error.excel_filters_do_not_match': 'Excel filters do not match',
  'error.excel_filter_values_do_not_match': 'Excel filter values do not match',
  'error.user_dashboards_are_not_visible_to_admin': 'User dashboards are not visible to administrators',
  'error.dashboard_admin_search_too_short': 'Search query too short',
  'error.dashboard_admin_ehak_role_must_have_no_subrole': 'Ehak role cannot have subroles',
  'error.dashboard_admin_role_must_have_subrole': 'Role must have subroles',
  'error.dashboard_admin_regional_dashboard_must_have_region': 'Dashboard must have a region',
  'error.dashboard_admin_regional_dashboard_invalid_region': 'Dashboard region is invalid',
  'error.dashboard_admin_dashboard_must_have_status': 'Dashboard must have a status',
  'error.dashboard_admin_name_is_mandatory': 'Name is required',
  'error.dashboard_admin_name_already_exists': 'Name is already in use',
  'error.dashboard_widget_unknown_ehak': 'Widget has an unknown ehak code',
  'error.widget_admin_query_too_short': 'Search query too short',
  'error.widget_admin_end_date_incorrect': 'Widget end date is not correct',
  'error.widget_admin_start_date_incorrect': 'Widget start date is not correct',
  'error.widget_admin_start_is_after_end': 'Widget start date is after end date',
  'error.widget_admin_cube_code_missing': 'Widget cube code is missing',
  'error.widget_admin_excel_name_missing': 'Excel file name is missing',
  'error.widget_admin_dimensions_missing': 'Widget dimensions are missing',
  'error.widget_admin_periods_must_be_positive': 'Widget periods must be positive',
  'error.widget_admin_time_period_missing': 'Widget time period is missing',
  'error.widget_admin_status_missing': 'Widget status is missing',
  'error.widget_admin_name_en_missing': 'Widget english name is missing',
  'error.widget_admin_name_et_missing': 'Widget estonian name is missing',
  'error.widget_admin_shortname_et_missing': 'Widget estonian shortname is missing',
  'error.widget_admin_shortname_en_missing': 'Widget english shortname is missing',
  'error.domain_admin_shortname_et_missing': 'Domain estonian shortname is missing',
  'error.domain_admin_shortname_en_missing': 'Domain english shortname is missing',
  'error.domain_admin_levelname_et_missing': 'Domain level name is missing',
  'error.domain_admin_levelname_et_invalid': 'Domain level name is invalid',
  'error.graph_type_admin_no_type': 'Graph has no type',
  'error.graph_type_admin_excel_only_line_and_bar': 'Graph can only be of type line or barchart',
  'error.graph_type_admin_line_bar_stacked_area_must_have_time_axis': 'Graph must have a time axis',
  'error.filter_admin_no_type': 'Filter has no type',
  'error.filter_admin_no_name_et': 'Filter estonian name missing',
  'error.filter_admin_no_name_en': 'Filter english name missing',
  'error.filter_admin_time_part_filter_must_have_time_part': 'Filter must have a time part',
  'error.filter_admin_time_axis_must_have_0_values': 'Filter time axis must have 0 values',
  'error.filter_admin_filter_must_have_values': 'Filter must have values',
  'error.filter_admin_axis_only_1': 'Filter can only have 1 axis',
  'error.filter_admin_axis_must_have_order': 'Filter axis must have an order',
  'error.filter_admin_max_1_legend': 'Filter can only have 1 legend',
  'error.filter_admin_max_4_legend_menu': 'Filter can have up to 4 legend menus',
  'error.filter_admin_max_1_region': 'Filter can only have 1 region',
  'error.filter_admin_max_1_time': 'Filter can only have 1 time value',
  'error.filter_value_admin_no_value_en': 'Filter has no english value name',
  'error.filter_value_admin_no_value_et': 'Filter has no estonian value name',
  'error.widget_admin_widget_exists_with_another_connection': 'Widget already exists with another connection',
  'error.illegal_graph_type': 'Invalid graph type',
  'error.missing_graph_type': 'Graph type is missing',
  'error.missing_region': 'Region is missing',
  'error.dashboard_exists_with_another_connection': 'Dashboard already exists with another connection',
};
export default translations;
