import React from 'react';
import { Navbar } from 'react-bulma-components';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/statistikaamet-logo.svg';
import logoEn from '../../../assets/images/statistikaamet-logo-en.svg';
import { useMedia } from '../../../shared/hooks';
import NavMobile from '../NavMobile';
import NavMobileBurger from '../NavMobile/NavMobileBurger';
import Breadcrumb from './Breadcrumb';
import NavItemsPrimary from './NavItemsPrimary';
import './NavPrimary.scss';
import '../NavMobile/NavPrimaryMobile.scss';
import { useMenu, useDrawer } from '../../../dashboard/UIProvider';
import { useActiveLanguageCode } from '../../../i18n/LanguageProvider';

const NavPrimary = ({ additionalMenuItems = null, dashboard }) => {
  const { isMenuOpen, closeMenu } = useMenu();
  const { toggleDrawer } = useDrawer();
  const isMobile = useMedia(['(max-width: 768px)', '(min-width: 769px)'], [true, false], false);
  const isTablet = useMedia(['(max-width: 1023px)', '(max-width: 769px)'], [true, false], false);
  const isTouchDevice = isMobile || isTablet;
  const lang = useActiveLanguageCode();
  const clickHandler = () => {
    toggleDrawer();
    closeMenu();
  };

  return (
    <div className="navbar-wrapper navbar-primary">
      <Navbar className="navbar-primary dotted" aria-label="main navigation" active={isMenuOpen}>
        <Navbar.Brand>
          <Link to="/" className="navbar-item">
            <img src={lang === 'et' ? logo : logoEn} className="header-logo" alt="Statameti logo" />
          </Link>
          {dashboard && isTouchDevice && (
            <div className="navbar-item-mobile" onClick={clickHandler}>
              <NavItemsPrimary additionalMenuItems={additionalMenuItems} />
            </div>
          )}
          {isTouchDevice && <NavMobileBurger />}
        </Navbar.Brand>
        <Navbar.Menu active="true" id="primaryNavbar" className="primary-nav">
          {dashboard && !isMobile && !isTablet && (
            <Navbar.Container>
              <Navbar.Item>
                <Breadcrumb dashboard={dashboard} dashboardName={dashboard.name} />
              </Navbar.Item>
            </Navbar.Container>
          )}
          {isTouchDevice ? (
            <NavMobile dashboard={dashboard} />
          ) : (
            <Navbar.Container position="end">
              <NavItemsPrimary additionalMenuItems={additionalMenuItems} />
            </Navbar.Container>
          )}
        </Navbar.Menu>
      </Navbar>
    </div>
  );
};

export default withRouter(NavPrimary);
