import React from 'react';
import Headroom from 'react-headroom';
import './Header.scss';
import NavPrimary from './NavPrimary';
import NavSecondary from './NavSecondary';
import { useMenu } from '../../dashboard/UIProvider';

const Header = ({ dashboard, additionalMenuItems }) => {
  const { closeMenu } = useMenu();

  return (
    <Headroom onPin={closeMenu}>
      <NavSecondary />
      <NavPrimary dashboard={dashboard} additionalMenuItems={additionalMenuItems} />
    </Headroom>
  );
};

export default Header;
