export const SLUG = {
  DASHBOARD: `:dashboardSlug([-0-9a-zA-Z.]+-\\d+)`,
  REGION: `:regionSlug([-0-9a-zA-Z]+-\\d+)`,
  GRAPH: `:graphSlug(graafik-\\d+|graph-\\d+)`,
};

export const afterAndIncludingLastSlash = /\/[^/]+\/?$/;

export const ErrorCode = {
  DASHBOARD_IS_NOT_EXISTING: 'dashboard_is_not_existing',
  DASHBOARD_IS_HIDDEN: 'dashboard_is_hidden',
  DASHBOARD_MUST_BE_REGIONAL: 'dashboard_must_be_regional',
  MY_DASHBOARD_DOES_NOT_EXIST: 'my_dashboard_does_not_exist',
  MY_DASHBOARD_MUST_HAVE_USER: 'my_dashboard_must_have_user',
  WIDGET_IS_NOT_EXISTING: 'widget_is_not_existing',
  WIDGET_IS_NOT_EXISTING_OR_ADDED_TO_DASHBOARD: 'widget_is_not_existing_or_added_to_dashboard',
  WIDGET_IS_HIDDEN: 'widget_is_hidden',
  USER_WIDGET_IS_NOT_EXISTING: 'user_widget_is_not_existing',
  UPLOADED_FILE_HAS_NO_NAME: 'uploaded_file_has_no_name',
  UPLOADED_FILE_HAS_NO_EXTENSION: 'uploaded_file_has_no_extension',
  UPLOADED_FILE_IS_NOT_EXCEL: 'uploaded_file_is_not_excel',
  EXCEL_FILTERS_DO_NOT_MATCH: 'excel_filters_do_not_match',
  EXCEL_FILTER_VALUES_DO_NOT_MATCH: 'excel_filter_values_do_not_match',
  USER_DASHBOARDS_ARE_NOT_VISIBLE_TO_ADMIN: 'user_dashboards_are_not_visible_to_admin',
  DASHBOARD_ADMIN_SEARCH_TOO_SHORT: 'dashboard_admin_search_too_short',
  DASHBOARD_ADMIN_EHAK_ROLE_MUST_HAVE_NO_SUBROLE: 'dashboard_admin_ehak_role_must_have_no_subrole',
  DASHBOARD_ADMIN_ROLE_MUST_HAVE_SUBROLE: 'dashboard_admin_role_must_have_subrole',
  DASHBOARD_ADMIN_REGIONAL_DASHBOARD_MUST_HAVE_REGION: 'dashboard_admin_regional_dashboard_must_have_region',
  DASHBOARD_ADMIN_REGIONAL_DASHBOARD_INVALID_REGION: 'dashboard_admin_regional_dashboard_invalid_region',
  DASHBOARD_ADMIN_DASHBOARD_MUST_HAVE_STATUS: 'dashboard_admin_dashboard_must_have_status',
  DASHBOARD_ADMIN_NAME_IS_MANDATORY: 'dashboard_admin_name_is_mandatory',
  DASHBOARD_ADMIN_NAME_ALREADY_EXISTS: 'dashboard_admin_name_already_exists',
  DASHBOARD_WIDGET_UNKNOWN_EHAK: 'dashboard_widget_unknown_ehak',
  WIDGET_ADMIN_QUERY_TOO_SHORT: 'widget_admin_query_too_short',
  WIDGET_ADMIN_END_DATE_INCORRECT: 'widget_admin_end_date_incorrect',
  WIDGET_ADMIN_START_DATE_INCORRECT: 'widget_admin_start_date_incorrect',
  WIDGET_ADMIN_START_IS_AFTER_END: 'widget_admin_start_is_after_end',
  WIDGET_ADMIN_CUBE_CODE_MISSING: 'widget_admin_cube_code_missing',
  WIDGET_ADMIN_EXCEL_NAME_MISSING: 'widget_admin_excel_name_missing',
  WIDGET_ADMIN_DIMENSIONS_MISSING: 'widget_admin_dimensions_missing',
  WIDGET_ADMIN_PERIODS_MUST_BE_POSITIVE: 'widget_admin_periods_must_be_positive',
  WIDGET_ADMIN_TIME_PERIOD_MISSING: 'widget_admin_time_period_missing',
  WIDGET_ADMIN_STATUS_MISSING: 'widget_admin_status_missing',
  WIDGET_ADMIN_NAME_EN_MISSING: 'widget_admin_name_en_missing',
  WIDGET_ADMIN_NAME_ET_MISSING: 'widget_admin_name_et_missing',
  WIDGET_ADMIN_SHORTNAME_ET_MISSING: 'widget_admin_shortname_et_missing',
  WIDGET_ADMIN_SHORTNAME_EN_MISSING: 'widget_admin_shortname_en_missing',
  DOMAIN_ADMIN_SHORTNAME_ET_MISSING: 'domain_admin_shortname_et_missing',
  DOMAIN_ADMIN_SHORTNAME_EN_MISSING: 'domain_admin_shortname_en_missing',
  DOMAIN_ADMIN_LEVELNAME_ET_MISSING: 'domain_admin_levelname_et_missing',
  DOMAIN_ADMIN_LEVELNAME_ET_INVALID: 'domain_admin_levelname_et_invalid',
  GRAPH_TYPE_ADMIN_NO_TYPE: 'graph_type_admin_no_type',
  GRAPH_TYPE_ADMIN_EXCEL_ONLY_LINE_AND_BAR: 'graph_type_admin_excel_only_line_and_bar',
  GRAPH_TYPE_ADMIN_LINE_BAR_STACKED_AREA_MUST_HAVE_TIME_AXIS:
    'graph_type_admin_line_bar_stacked_area_must_have_time_axis',
  FILTER_ADMIN_NO_TYPE: 'filter_admin_no_type',
  FILTER_ADMIN_NO_NAME_ET: 'filter_admin_no_name_et',
  FILTER_ADMIN_NO_NAME_EN: 'filter_admin_no_name_en',
  FILTER_ADMIN_TIME_PART_FILTER_MUST_HAVE_TIME_PART: 'filter_admin_time_part_filter_must_have_time_part',
  FILTER_ADMIN_TIME_AXIS_MUST_HAVE_0_VALUES: 'filter_admin_time_axis_must_have_0_values',
  FILTER_ADMIN_FILTER_MUST_HAVE_VALUES: 'filter_admin_filter_must_have_values',
  FILTER_ADMIN_AXIS_ONLY_1: 'filter_admin_axis_only_1',
  FILTER_ADMIN_AXIS_MUST_HAVE_ORDER: 'filter_admin_axis_must_have_order',
  FILTER_ADMIN_MAX_1_LEGEND: 'filter_admin_max_1_legend',
  FILTER_ADMIN_MAX_4_LEGEND_MENU: 'filter_admin_max_4_legend_menu',
  FILTER_ADMIN_MAX_1_REGION: 'filter_admin_max_1_region',
  FILTER_ADMIN_MAX_1_TIME: 'filter_admin_max_1_time',
  FILTER_VALUE_ADMIN_NO_VALUE_EN: 'filter_value_admin_no_value_en',
  FILTER_VALUE_ADMIN_NO_VALUE_ET: 'filter_value_admin_no_value_et',
  WIDGET_ADMIN_WIDGET_EXISTS_WITH_ANOTHER_CONNECTION: 'widget_admin_widget_exists_with_another_connection',
  ILLEGAL_GRAPH_TYPE: 'illegal_graph_type',
  MISSING_GRAPH_TYPE: 'missing_graph_type',
  MISSING_REGION: 'missing_region',
  DASHBOARD_EXISTS_WITH_ANOTHER_CONNECTION: 'dashboard_exists_with_another_connection',
  FILTER_VALUE_CUSTOM_ADMIN_DELETE_FORBIDDEN_ALREADY_REFERENCED_IN_A_FILTER:
    'filter_value_custom_admin_delete_forbidden_already_referenced_in_a_filter',
};
