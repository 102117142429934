import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/sass/print.scss';
import './assets/sass/styles.scss';
import App from './container/App/App';
import './tracker.js';

if (process.env.REACT_APP_VERSION) console.log('Version', process.env.REACT_APP_VERSION);

ReactDOM.render(<App />, document.getElementById('root'));
