import React from 'react';
import et from './et';
import en from './en';
import { useLocalStorage } from '../shared/hooks';

const languages = ['et', 'en'];
const translations = { et, en };

export const LanguageContext = React.createContext();

export const Translate = ({ children, ...props }) => {
  const { translate } = React.useContext(LanguageContext);

  return typeof children === 'function' ? children(translate) : translate(children, props) || children || null;
};

const LanguageProvider = ({ children }) => {
  const [activeLanguageCode, setActiveLanguageCode] = useLocalStorage('activeLanguage', languages[0]);

  const additionalProps = React.useMemo(() => ({ setActiveLanguageCode }));

  return (
    <FixedLanguageProvider languageCode={activeLanguageCode} additionalProps={additionalProps}>
      {children}
    </FixedLanguageProvider>
  );
};

export const FixedLanguageProvider = ({
  languageCode: fixedLanguageCode = languages[0],
  children,
  additionalProps
}) => {
  const translate = React.useCallback(
    (translationId, { languageCode = fixedLanguageCode, ...props } = {}) => {
      const translation = translations[languageCode][translationId];
      return typeof translation === 'function' ? translation(props) : translation;
    },
    [fixedLanguageCode]
  );

  const value = React.useMemo(
    () => ({ languages, translate, activeLanguageCode: fixedLanguageCode, ...additionalProps }),
    [fixedLanguageCode, additionalProps]
  );

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useActiveLanguageCode = () => React.useContext(LanguageContext).activeLanguageCode;
export const useLanguages = () => React.useContext(LanguageContext).languages;

export const Title = () => {
  const { translate, activeLanguageCode } = React.useContext(LanguageContext);

  React.useEffect(() => {
    if (activeLanguageCode) {
      document.title = translate('stat.app.title');
    }
  }, [activeLanguageCode]);

  return null;
};

export const useTranslate = () => React.useContext(LanguageContext).translate;

export default LanguageProvider;
