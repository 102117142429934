import { flatten, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../api/ApiProvider';
import { useIsGuest } from '../auth/UserProvider';
import { useActiveLanguageCode, useTranslate } from '../i18n/LanguageProvider';
import {
  ApiDashboardPreferencesProvider,
  MyDashboardPreferencesProvider,
  LocalStorageDashboardPreferencesProvider,
} from '../preferences/dashboard';
import { LocalStorageAllWidgetsPreferencesProvider } from '../preferences/widget';
import { usePromiseWithRefresh } from '../shared/hooks';
import { toastError } from '../notifications';
import { getSlug } from '../shared/utils';

export const DashboardContext = React.createContext({});

const DashboardProviderWithPreferences = ({ children, dashboardId }) =>
  dashboardId === 'me' ? (
    <MyDashboardProvider dashboardId={dashboardId} key={dashboardId}>
      <LocalStorageAllWidgetsPreferencesProvider>
        <MyDashboardPreferencesProvider>{children}</MyDashboardPreferencesProvider>
      </LocalStorageAllWidgetsPreferencesProvider>
    </MyDashboardProvider>
  ) : (
    <DashboardProvider dashboardId={dashboardId} key={dashboardId}>
      <LocalStorageAllWidgetsPreferencesProvider>
        {React.createElement(
          useIsGuest() ? LocalStorageDashboardPreferencesProvider : ApiDashboardPreferencesProvider,
          null,
          children
        )}
      </LocalStorageAllWidgetsPreferencesProvider>
    </DashboardProvider>
  );

const DashboardProvider = ({ dashboardId, children }) => {
  const getDashboard = useGetDashboard();
  const translate = useTranslate();
  const activeLanguageCode = useActiveLanguageCode();

  const [dashboard, refreshDashboard, setDashboard] = usePromiseWithRefresh(
    () => getDashboard(dashboardId, activeLanguageCode),
    [dashboardId, activeLanguageCode]
  );

  if (dashboard) {
    const defaultDashboardName = translate('stat.url.dashboard', { lang: activeLanguageCode });
    dashboard.slug = dashboard.name
      ? `${getSlug(dashboard.name)}-${dashboard.id}`
      : `${defaultDashboardName}-${dashboard.id}`;
  }

  if (dashboard && dashboard.regions) {
    dashboard.regions = dashboard.regions.map(region => {
      region.slug = getSlug(`${region.name}-${region.id}`);
      return region;
    });
  }

  const value = { dashboardId, dashboard, refreshDashboard, setDashboard };

  if (!dashboard) return null;

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

const MyDashboardProvider = ({ children }) => {
  const getDashboard = useGetDashboard();
  const activeLanguageCode = useActiveLanguageCode();
  const fetch = useFetch();

  const [dashboard, refreshDashboard, setDashboard] = usePromiseWithRefresh(
    () => getDashboard('me', activeLanguageCode),
    [activeLanguageCode]
  );

  const setMyDashboardVisited = () =>
    fetch(`/v2/dashboard/${dashboard.id}/widgets`, {
      method: 'PATCH',
      body: {},
    });

  const value = {
    dashboardId: dashboard ? dashboard.id : null,
    dashboard,
    refreshDashboard,
    setDashboard,
    isPersonalDashboard: true,
    setMyDashboardVisited,
  };

  if (!dashboard) return null;

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

const useGetDashboard = () => {
  const fetch = useFetch();
  const activeLanguageCode = useActiveLanguageCode();
  const translate = useTranslate();
  const history = useHistory();

  return (dashboardId, languageCode = activeLanguageCode) =>
    fetch(`/v2/dashboard/${dashboardId}`, {
      queryParams: { language: languageCode },
      errorNotification: false,
    }).catch(response => {
      response.json().then(error => {
        if (error.httpStatus === 400) history.replace('/');
        else if (error.httpStatus === 404) {
          toastError(translate('api.myDashboard.notExist'));
          history.replace('/');
        } else {
          toastError(translate('api.operationFailed'));
        }
        throw error;
      });
    });
};

export const useDomains = () => {
  const { elements = [] } = useCurrentDashboard();

  return useMemo(() => sortBy(elements.reduce(toDomains, []), 'orderNr'), [elements]);
};

export const useWidgets = () => {
  const domains = useDomains();
  const dashboard = useCurrentDashboard();

  return useMemo(() => (dashboard.widgets ? dashboard.widgets : flatten(domains.map(d => d.widgets))), [domains]);
};

const toDomains = (result, { subElements = [], widgets = [], ...element }) => {
  result.push({ widgets, ...element }, ...subElements.reduce(toDomains, []));

  return result;
};

export const useCurrentDashboard = () => {
  const { dashboard } = React.useContext(DashboardContext);

  return dashboard;
};

export const useGetRegionSlugById = id => {
  const { dashboard } = React.useContext(DashboardContext);
  if (!dashboard.regions) return null;
  const region = dashboard.regions.find(r => r.id === id);
  if (!region) return null;
  return getSlug(`${region.name}-${region.id}`);
};

export const useIsPersonalDashboard = () => React.useContext(DashboardContext).isPersonalDashboard;
export const useSetMyDashboardVisited = () => React.useContext(DashboardContext).setMyDashboardVisited;

export default DashboardProviderWithPreferences;
