export const COOKIE_CONSENT_AGREED_NAME = 'consent-agreed';
export const COOKIE_NAME_LIST = [
  '_gat_UA-5303491-1',
  '_ga',
  '_gid',
  '_fbp',
  'alphachat-active-session',
  'alphachat-test',
  'alphachat-enduser-token',
  'alphachat-user-data',
];

const GOOGLE_TAG_MANAGER_IFRAME_ID = 'googleTagManagerIframe';
export const COOKIE_CONSENT_EXPIRATION_MS = Date.now() + 100 * 24 * 60 * 60 * 1000;

export const CookieConsentValue = {
  accept: 'true',
  reject: 'false',
};

const developmentCookieOptions = {
  path: '/',
};

const productionCookieOptions = {
  path: '/',
  domain: '.stat.ee',
};

export const cookieOptions = process.env.NODE_ENV === 'production' ? productionCookieOptions : developmentCookieOptions;

export const addCookieScript = () => {
  const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const iframe = document.getElementById(GOOGLE_TAG_MANAGER_IFRAME_ID);

  if (process.env.NODE_ENV === 'production' && !iframe) {
    // eslint-disable-next-line no-eval
    eval(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_ID}');`);

    const tagManagerIframe = document.createElement('iframe');
    tagManagerIframe.id = GOOGLE_TAG_MANAGER_IFRAME_ID;
    tagManagerIframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
    tagManagerIframe.width = 0;
    tagManagerIframe.height = 0;
    tagManagerIframe.style.display = 'none';
    tagManagerIframe.style.visibility = 'hidden';
    document.body.insertBefore(tagManagerIframe, document.body.firstChild);
  }
};

export const removeCookieScript = () => {
  const iframe = document.getElementById(GOOGLE_TAG_MANAGER_IFRAME_ID);
  if (process.env.NODE_ENV === 'production' && iframe) {
    document.body.removeChild(iframe);
  }
};
