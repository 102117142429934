import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import fondLogo from '../../assets/images/fond_logo.jpg';
import fondLogoEn from '../../assets/images/fond_logo_en.jpg';
import { Columns, Heading, Section, Container } from 'react-bulma-components';
import { Footer as BulmaFooter } from 'react-bulma-components';
import { Translate, useActiveLanguageCode } from '../../i18n/LanguageProvider';
import CookieNotification from '../../container/Dashboard/CookieNotification';
import { useMedia } from '../../shared/hooks';
import './Footer.scss';
import Icon from '@mdi/react';
import { mdiPhoneOutline, mdiEmailOutline } from '@mdi/js';
import { COOKIE_CONSENT_AGREED_NAME } from '../../shared/cookieUtils';

const Footer = () => {
  const lang = useActiveLanguageCode();
  const isMobile = useMedia(['(max-width: 768px)', '(min-width: 769px)'], [true, false], false);
  const [cookies] = useCookies([COOKIE_CONSENT_AGREED_NAME]);

  const [showCookieNotification, setShowCookieNotification] = useState(false);

  const handleShowCookieNotification = isShown => {
    setShowCookieNotification(previousValue => {
      return cookies[COOKIE_CONSENT_AGREED_NAME] === undefined ? isShown : !previousValue;
    });
  };

  return (
    <>
      <CookieNotification show={showCookieNotification} onShow={handleShowCookieNotification} />
      <BulmaFooter>
        <Container>
          <Section>
            <Columns>
              <Columns.Column>
                <Heading size={isMobile ? 6 : 5}>
                  <Translate>stat.footer.statInfo</Translate>
                </Heading>
                <ul>
                  <li>
                    <Icon path={mdiPhoneOutline} size={0.75} />
                    +372 625 9300
                  </li>
                  <li>
                    <Icon path={mdiEmailOutline} size={0.75} />
                    <a href="mailto:stat@stat.ee">stat@stat.ee</a>
                  </li>
                </ul>
              </Columns.Column>
              <Columns.Column>
                <div>
                  <a onClick={() => handleShowCookieNotification(true)}>
                    <Translate>stat.cookie.compliance.settings</Translate>
                  </a>
                </div>
              </Columns.Column>
              <Columns.Column className="is-one-quarter-desktop is-one-third-tablet">
                <div>
                  <img
                    src={lang === 'et' ? fondLogo : fondLogoEn}
                    className="fond-logo-mobile"
                    alt="Struktuurfondi logo"
                  />
                </div>
              </Columns.Column>
            </Columns>
          </Section>
        </Container>
      </BulmaFooter>
    </>
  );
};

export default Footer;
