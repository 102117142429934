import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import LangSelector from '../NavSecondary/LangSelector';
import { useTranslate, Translate } from '../../../i18n/LanguageProvider';
import Select from '../../../components/Select';
import { useIsGuest } from '../../../auth/UserProvider';
import { useIsPersonalDashboard, useWidgets } from '../../../dashboard/DashboardProvider';
import { useMenu } from '../../../dashboard/UIProvider';
import Login from '../Login';
import { useSlugIds } from '../../../shared/hooks';
import { afterAndIncludingLastSlash } from '../../../shared/constants';

export const NavItemUser = ({ user, onClickLogout }) => {
  const isGuest = useIsGuest();
  const history = useHistory();
  const translate = useTranslate();
  const isPersonalDashboard = useIsPersonalDashboard();
  const userMenuOptions = [
    { value: 'user', name: user ? user.username : null },
    ...(!isGuest && !isPersonalDashboard ? [{ value: 'myDashboard', name: translate('stat.header.myDashboard') }] : []),
    { value: 'logOut', name: translate('stat.header.logout') }
  ];

  const onMenuOptionChange = ({ value }) => (value === 'myDashboard' ? history.push('/dashboard/me') : onClickLogout());

  return (
    <div className="nav-item-mobile nav-item-mobile-user">
      <LangSelector />
      {!isGuest ? (
        <Select
          value={userMenuOptions[0]}
          options={userMenuOptions}
          onChange={onMenuOptionChange}
          getOptionValue={o => o.value}
          getOptionLabel={o => o.name}
        />
      ) : (
        <Login />
      )}
    </div>
  );
};

export const NavItemRegionSelect = ({ dashboard, region, onRegionChange }) => (
  <div className="nav-item-mobile">
    <div className="region">
      <Select
        placeholder={<Translate>components.Select.MenuList.placeholderRegion</Translate>}
        value={dashboard.regions.find(r => r.id === region)}
        options={dashboard.regions}
        onChange={onRegionChange}
        getOptionValue={o => o.id}
        getOptionLabel={o => o.name}
      />
    </div>
  </div>
);

export const NavItemDashboard = ({ dashboard }) => {
  const { params, path } = useRouteMatch();
  const history = useHistory();
  const isPersonalDashboard = useIsPersonalDashboard();
  const { graphId } = useSlugIds();

  const handleDashboardClick = () => {
    if (!graphId) return;
    const nextPath = generatePath(path, { ...params });
    history.replace(nextPath.replace(afterAndIncludingLastSlash, ''));
  };

  return (
    <div className="highlightable nav-item-mobile">
      <div className="dashboard" onClick={handleDashboardClick}>
        <span>{isPersonalDashboard ? <Translate>stat.header.myDashboard</Translate> : dashboard.name}</span>
      </div>
    </div>
  );
};

export const NavItemAllDashboards = () => {
  const history = useHistory();
  const { closeMenu } = useMenu();
  const handleBackBtn = () => {
    closeMenu();
    history.push('/');
  };

  return (
    <div className="nav-item-mobile">
      <div className="back-button" onClick={handleBackBtn}>
        <Icon path={mdiChevronLeft} size={1.15} className="is-separator" />
        <span>
          <Translate>stat.domains.allDashboards</Translate>
        </span>
      </div>
    </div>
  );
};

export const NavItemGraph = ({ graphId }) => {
  const allWidgets = useWidgets();
  const widget = allWidgets.find(w => w.id === graphId);
  return (
    <div className="highlightable nav-item-mobile">
      <div className="graph">{widget ? widget.shortname : '404'}</div>
    </div>
  );
};
