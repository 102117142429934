import React from 'react';
import { Button } from 'react-bulma-components';
import { Translate } from '../../../i18n/LanguageProvider';

const Login = () => {
  return (
    <form className="is-flex" action="/api/login/tara/authenticate" method="get">
      <Button submit color="primary" className="login">
        <Translate>stat.login.enter</Translate>
      </Button>
    </form>
  );
};

export default Login;
