import React from 'react';
import { withRouter } from 'react-router';
import { useToken } from '../../../auth/TokenProvider';
import { useCurrentUser, useIsGuest } from '../../../auth/UserProvider';
import { useTranslate } from '../../../i18n/LanguageProvider';
import { clearDashboardPreferences, useRegion } from '../../../preferences/dashboard';
import { clearWidgetPreferences } from '../../../preferences/widget';
import { useSlugIds } from '../../../shared/hooks';
import {
  NavItemDashboard,
  NavItemUser,
  NavItemAllDashboards,
  NavItemRegionSelect,
  NavItemGraph
} from './NavItemsPrimaryMobile';
import './NavPrimaryMobile.scss';

const NavMobile = ({ history, dashboard: currentDashboard }) => {
  const translate = useTranslate();
  const user = useCurrentUser();
  const [, setToken] = useToken();
  const isGuest = useIsGuest();
  const [region, setRegion] = useRegion();
  const { graphId } = useSlugIds();

  const onRegionChange = region => setRegion(parseInt(region.id));
  const onDashboardChange = id => history.push(`/dashboard/${id}`);

  const onClickLogout = () => {
    if (!window.confirm(translate(isGuest ? 'stat.logout.confirmGuest' : 'stat.logout.confirm'))) return;

    if (isGuest) {
      clearDashboardPreferences();
      clearWidgetPreferences();
    }
    history.push('/');
    setToken('guest');
  };

  return (
    <>
      {currentDashboard && (
        <>
          <NavItemAllDashboards />
          <NavItemDashboard dashboard={currentDashboard} onDashboardChange={onDashboardChange} />
          {currentDashboard.type === 'REGIONAL' && (
            <NavItemRegionSelect dashboard={currentDashboard} region={region} onRegionChange={onRegionChange} />
          )}
          {graphId && <NavItemGraph graphId={graphId} />}
        </>
      )}

      <NavItemUser
        user={user}
        onClickLogout={onClickLogout}
        isGuest={isGuest}
        history={history}
        translate={translate}
      />
    </>
  );
};

export default withRouter(NavMobile);
