import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';

export const toastError = (message, options) =>
  toast(message, {
    type: 'error',
    ...options,
  });

export const toastSuccess = (message, options) =>
  toast(message, {
    type: 'success',
    ...options,
  });

export const toastInfo = (message, options) =>
  toast(message, {
    type: 'info',
    ...options,
  });

export const ToastProvider = props => {
  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar theme="colored" transition={Slide} />
      {props.children}
    </>
  );
};
